import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./ColumnManager.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import classNames from "classnames";
import {marginTop, themeColors} from "../../theme";
import { connect, useDispatch } from "react-redux";
import { MdOutlineDragIndicator } from "react-icons/md";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { calllogFilterValues, contactFilterValues, taskFilterValues } from "../../../Values/constants";
import { IoIosClose } from "react-icons/io";
import { googleAnalyticsCTAClick } from "../../../Services/users";

type props = {
  columnsList: any[];
  open: boolean;
  close: () => void;
  onChangeColumns?: (changeList: []) => void;
  toggleAllColumns?: any;
  theme:any;
  organizationData:any;
  tableType?:any;
  user?:any;
  userRole: any;
};

var row: any;

const ColumnManagerModal: FunctionComponent<props> = ({
  columnsList,
  open,
  close,
  onChangeColumns,
  toggleAllColumns,
  theme,
  organizationData,
  tableType,
  user,
  userRole
}) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [sample, setSample] = useState<any[]>([]);

  useEffect(() => {
    let data = [...columnsList];
    setSample(data);
  }, [columnsList]);

  const start = (event: any) => {
    row = event.target;
  };
  const dragover = (event: any) => {
    var e = event;
    e.preventDefault();
    let children = Array.from(e.target.parentNode.parentNode.children);
    if (children.length === columnsList.length) {
      if (children.indexOf(e.target.parentNode) > children.indexOf(row)) {
        e.target.parentNode.after(row);
        const content = reorder(
          sample,
          children.indexOf(row),
          children.indexOf(e.target.parentNode)
        );
        setSample(content);
      } else {
        e.target.parentNode.before(row);
        const content = reorder(
          sample,

          children.indexOf(row),
          children.indexOf(e.target.parentNode)
        );
        setSample(content);
      }
    }
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onSave = async () => {
    let data: any = [];
    let hideColumn:any=[];
    let showColumn:any=[];
    sample.map((item) => {
      let myObject:any = {};
      if(item.isVisible === false){
        myObject['columnHeader']=item.id
        hideColumn.push(item.id)
      }
      if(item.isVisible === true){
        showColumn.push(item.id)
      }
      data.push(item.id)
    });
    if(tableType === 'User'){
      googleAnalyticsCTAClick("Contact Column Manager Save Button","Contact Screen",user)
      localStorage.setItem('contactHiddenColumn', JSON.stringify(hideColumn));
      localStorage.setItem('contactShowFilterValues', JSON.stringify(showColumn));
    }
    else if(tableType === 'CallLogs'){
      googleAnalyticsCTAClick("Call Log Column Manager Save Button","Call Log Column Screen",user)
      localStorage.setItem('calllogHiddenColumn', JSON.stringify(hideColumn));
      localStorage.setItem('calllogShowFilterValues', JSON.stringify(showColumn));
    }
    else if(tableType === 'Task'){
      googleAnalyticsCTAClick("Task Column Manager Save Button","Task Screen",user)
      localStorage.setItem('taskHiddenColumn', JSON.stringify(hideColumn));
      localStorage.setItem('taskShowFilterValues', JSON.stringify(showColumn));
    }
    if(tableType === 'LeadDistributor'){
      googleAnalyticsCTAClick("Lead Distribution Column Manager Save Button","Lead Distribution Screen",user)
    }
    else if(tableType === 'API Leads'){
      googleAnalyticsCTAClick("API Leads Column Manager Save Button","API Leads Screen",user)
    }
    else if(tableType === 'projects'){
      googleAnalyticsCTAClick("Listing Column Manager Save Button","Listing Screen",user)
    }
    // localStorage.setItem('ContactColumn', column);
    // let res = await axios.post(url+'/columnManager/addColumn', {
    //   organization_id:user.organization_id?user.organization_id:"",
    //   uid:user.uid?user.uid:"",
    //   user_email:user.user_email?user.user_email:"",
    //   columnType:tableType,
    //   columnData : column
    // })
    onChangeColumns && onChangeColumns(data);
    close();
    if(tableType === 'User' || tableType === 'CallLogs' || tableType === 'Task'){
      window.location.reload()
    }
  };

  const onCancel = () => {
    if(tableType === 'User'){
      googleAnalyticsCTAClick("Contact Column Manager Cancel Button","Contact Screen",user)
    }
    else if(tableType === 'CallLogs'){
      googleAnalyticsCTAClick("Call Log Column Manager Cancel Button","CallLogs Screen",user)
    }
    else if(tableType === 'Task'){
      googleAnalyticsCTAClick("Task Column Manager Cancel Button","Task Screen",user)
    }
    else if(tableType === 'LeadDistributor'){
      googleAnalyticsCTAClick("Lead Distribution Column Manager Cancel Button","Lead Distribution Screen",user)
    }
    else if(tableType === 'API Leads'){
      googleAnalyticsCTAClick("API Leads Column Manager Cancel Button","API Leads Screen",user)
    }
    else if(tableType === 'projects'){
      googleAnalyticsCTAClick("Listing Column Manager Cancel Button","Listing Screen",user)
    }
    close();
  };

  const onReset = () => {
    // let data = [...columnsList];
    // toggleAllColumns(false);
    // setSample(data);
    if(tableType === 'LeadDistributor'){
      googleAnalyticsCTAClick("Lead Distribution Column Manager Reset Button","Lead Distribution Screen",user)
    }
    else if(tableType === 'API Leads'){
      googleAnalyticsCTAClick("API Leads Column Manager Reset Button","API Leads Screen",user)
    }
    else if(tableType === 'projects'){
      googleAnalyticsCTAClick("Listing Column Manager Reset Button","Listing Screen",user)
    }

    if(tableType === 'User' || tableType === 'CallLogs' || tableType === 'Task'){
      let dataArray:any = [];
      if(tableType === 'User'){
        googleAnalyticsCTAClick("Contact Column Manager Reset Button","Contact Screen",user)
        // dataArray=contactFilterValues
        localStorage.removeItem('contactHiddenColumn');
        localStorage.removeItem('contactShowFilterValues');
        localStorage.removeItem('contactColumnOrder');
      }
      else if(tableType === 'CallLogs'){
        // dataArray=calllogFilterValues
        googleAnalyticsCTAClick("Call Log Column Manager Reset Button","CallLogs Screen",user)
        localStorage.removeItem('calllogHiddenColumn');
        localStorage.removeItem('calllogShowFilterValues');
        localStorage.removeItem('calllogColumnOrder');
      }
      else if(tableType === 'Task'){
        // dataArray=taskFilterValues
        googleAnalyticsCTAClick("Task Column Manager Reset Button","Task Screen",user)
        localStorage.removeItem('taskHiddenColumn');
        localStorage.removeItem('taskShowFilterValues');
        localStorage.removeItem('taskColumnOrder');
      }
      // let data = [...columnsList];
      // data.forEach(column => {
      //   if(dataArray.includes(column.id)){
      //     column["isVisible"] = true;
      //   }else{
      //     column["isVisible"] = false;
      //   }
      // })
      // setSample(data);
      window.location.reload()
    }
    else{
    let data = [...columnsList];
    toggleAllColumns(false);
    setSample(data);
    }
  };

  const filterClass = classNames(styles.parent, {
    [styles.showColumnAnim]: open === true,
  });

  const customStyles = {
    content: {
      backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
    },
  };
  return (
    <Modal
      style={{ overlay: { zIndex: 202 },...customStyles }}
      isOpen={open}
      className={filterClass}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className={styles.headContainer}>
      {userRole === 'organization' && 
      <div style={{display:"flex",flexDirection:"row",alignItems: "center"}}>
        <h6 className={styles.manageText} style={{ marginRight: "80px" }}>Manage Columns</h6>
        <div className={styles.icon} onClick={onCancel}>
        <IoIosClose size={35} color={"#808080"}/>
      </div>
      </div>
      }
      {userRole !== 'organization' && 
      <h6 className={styles.manageText}>Manage Columns</h6>
      }
        <div className={styles.searchBox}>
          <input
            className={styles.searchText}
            placeholder="Search"
            onChange={(val) => setsearchQuery(val.target.value)}
          />
          <AiOutlineSearch />
        </div>
      </div>

      <div className={styles.listContainer} style={{height:userRole === 'organization'?'400px':'350px'}}>
        {sample.map((item: any) => {
          if (item.Header.toLowerCase().includes(searchQuery.toLowerCase())) {
            return (
              <div
                key={item.id}
                className={styles.child}
                draggable={true}
                onDragStart={start}
                onDragOver={dragover}
              >
                <div style={{ display: 'flex'}}>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  {...item.getToggleHiddenProps()}
                />
                <p className={styles.text} style={{marginTop:marginTop.sm}}>{item.Header}</p>
                </div>
                <div >
                <DragIndicatorIcon fontSize={"small"} />
                </div>
              </div>
            );
          }
        })}
      </div>
{/* {userRole !== 'organization' &&  */}
{
  (tableType !== "Organization" && tableType !== "NEWS" && tableType !== "API") && (
    <div className={styles.buttonContainer}>
        <button 
        className={styles.cancelButton} 
        onClick={onCancel}>
          Cancel
        </button>
        <button className={styles.resetButton} onClick={onReset}>
          Reset
        </button>
        <button className={styles.saveButton} onClick={onSave}>
          Save
        </button>
      </div>
  )
}
{/* } */}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    organization: state.organization,
    theme: state.theme.isLightMode,
    organizationData: state.organization.organizationData,
    userRole: state.user.role,
  };
};

export default connect(mapStateToProps)(ColumnManagerModal);
