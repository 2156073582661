import React, { FunctionComponent, useEffect,useState } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Analytics from '../Screens/Analytics/Analytics';
import Reports from '../Screens/Reports/Reports';
import { Route, Redirect } from 'react-router-dom';
import UserPanel from '../Screens/UserPanel/UserPanel';
import AddContacts from '../Screens/AddContacts/AddContacts';
import ContactDetails from '../Screens/ContactDetails/ContactDetails';
import EditContact from '../Screens/EditContact/EditContact';
import { connect, useDispatch } from 'react-redux';
import { fetchOrganizationsUsers, fetchOrganizationsUsersFromMongo } from '../Services/users';
import CallLogsPanel from '../Screens/CallLogsPanel/CallLogsPanel';
import LeadManagerAnalytics from '../Screens/Analytics/LeadManagerAnalytics';
import Task from '../Screens/TasksPanel/TaskPanel';
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import AddBooking from '../Screens/Booking/AddBooking';
import Booking from '../Screens/Booking/Booking'
import AppUpdateAlert from "../Components/AppUpdateAlert/AppUpdateAlert";
import ReenquiredLeads from '../Screens/ReEnquiredLeads/ReenquiredLeads';
import helpModal from '../Components/Modals/help/helpModal';
import moment from "moment";
import {fetchSingleMBData} from "../Services/contacts"
import Response from "../Screens/Response/Response";
import PropWiseResponse from "../Screens/Response/ResponsesPropertyWise";

type props = {
  user: any;

  history: any;
  leadsStage: any;
};
const UserRoute: FunctionComponent<props> = ({ history, user, leadsStage }) => {
  const dispatcher = useDispatch();
  const [showAppUpdateAlert,setShowAppUpdateAlert] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const contact_id = localStorage.getItem("contact_id");

  //     // console.log("VFCD","I rannnn1 1111")
  //     localStorage.removeItem("contact_id")
  //     if (contact_id) {
  //       try {
  //         // Assuming fetchSingleMBData is an async function that fetches data
  //         const data = await fetchSingleMBData(contact_id);
  //         // console.log("contact_details data",data)
  //         // Navigate to /contactDetails and pass contact_id in state

  //         const formatDate = (date:any) => {
  //           return date ? moment(date).format('DD MMM YY, LT') : '';
  //         };
  //         history.push({
  //           pathname: "/contactDetails",
  //           state: { detail:{  ...data,
  //             created_at: formatDate(data['created_at']),
  //             lead_assign_time: formatDate(data['lead_assign_time']),
  //             next_follow_up_date_time: formatDate(data['next_follow_up_date_time']),
  //             modified_at: formatDate(data['modified_at']),
  //             stage_change_at: formatDate(data['stage_change_at']),
  //             feedback_time: formatDate(data['feedback_time']),
  //             due_date: formatDate(data['due_date']),
  //           } } // You can also pass the fetched data if needed
  //         });
  //       } catch (error) {
  //         console.error("Error fetching contact data", error);
  //       }
  //     }
  //   };

  //   fetchData(); // Call the async function
  // }, [history]);

  useEffect(() => {
    if (user.organization_id) {
      // const unSub = fetchOrganizationsUsers(
      //   dispatcher,
      //   user.organization_id,
      //   user
      // );
      // return () => {
      //   unSub();
      // };
      fetchOrganizationsUsersFromMongo(dispatcher,user.organization_id,user);
    }

    // eslint-disable-next-line
  }, [user]);

  // useEffect(()=>{
  //   let lastAlertTime:any = localStorage.getItem('lastAlertTime');

  //   // If the last alert time was more than 6 hours ago or is null (i.e. it has never been shown)
  //   if (!lastAlertTime || Date.now() - lastAlertTime > 3 * 60 * 60 * 1000) {
  //     // Show the alert
  //     setShowAppUpdateAlert(true);
  //     let currentTime: any = Date.now();

  //     // Update the last alert time in localStorage
  //     localStorage.setItem('lastAlertTime', currentTime);
  //   }
  // },[])
  return (
    <>
      <Navbar
        props={{ history }}
        title={'Contacts'}
        path={'/'}
        leadManger={false}
        callLogs={'/callLogs'}
        task={'/tasks'}
      />
          {/* {showAppUpdateAlert &&  <AppUpdateAlert setShowAppUpdateAlert={setShowAppUpdateAlert} />} */}
        <div className={styles.parent}>
        <AnalyticsSideNav
          props={{ history }}
          title={'Contacts'}
          path={'/'}
          leadManger={false}
          operationManger={false}
          callLogs={'/callLogs'}
          task={'/tasks'}
          // mmb={"/mmb"} 
        />
       {/* <Route exact path="/" component={UserPanel} /> */}
       <Route exact path="/">
          <Redirect to="/contacts" />
        </Route>
      <Route exact path="/contacts" component={UserPanel} />
      <Route
        path="/analytics"
        component={user.profile !== 'Sales' ? LeadManagerAnalytics : Analytics}
      />
      <Route path="/reports" component={Reports} />
      <Route path="/addContacts" component={AddContacts} />
      <Route path="/contactDetails" component={ContactDetails} />
      <Route path="/editContacts" component={EditContact} />
      <Route path="/callLogs" component={CallLogsPanel} />
      <Route path="/tasks" component={Task} />
      <Route path="/addBooking" component={AddBooking} />
      <Route path="/booking" component={Booking} />
      <Route path="/viewBookingDetails" component={AddBooking} />
      {/* <Route path="/reenquiredLeads" component={ReenquiredLeads} /> */}
      <Route path="/help" component={helpModal} />
        <Route exact path="/response" component={Response} />
        <Route exact path="/propwiseresponse" component={PropWiseResponse} />
      </div>
      
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
  };
};

export default connect(mapStateToProps)(UserRoute);
