import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./SignInOtp.module.css";
import Logo from "../../Assets/Images/LogoNewFigma.png";
import SignInImage from "../../Assets/Images/singInPageImage.svg";
import TextInput from "../../Components/TextInput/TextInput";
import SignInTextInput from "../../Components/SignInTextInput/SignInTextInput";
import PasswordInput from "../../Components/Password/PasswordInput";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInFirebase, signInMongo,generateOtp,createCaptcha } from "../../Services/auth";
import { emailValidate, passwordValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import Logo2 from "../../Assets/Images/ify_logo2.png";
// import LogoNew from "../../Assets/Images/ify_logo_new.png";
import LogoNew from "../../Assets/Images/IFY_Magicbricks_Logo.png";
import LogoOld from "../../Assets/Images/head.svg";
import Cookies from "universal-cookie";
import LatestBackground from "../../Assets/Images/signin_background_latest.svg";
import AppStore from "../../Assets/Images/appStoreIconNew.webp";
import PlayStore from "../../Assets/Images/playstoreIconNew.svg";
import { showSnackbarAction } from "../../Redux/actions";
import { googleAnalytics } from "../../Services/users";
import RefreshCaptchaImage from "../../Assets/Images/refresh_captcha_image.svg";
type props = {
    history: any;
};

const SignInOtp: FunctionComponent<props> = ({ history }) => {
    const emailRef: any = useRef();
    const passwordRef: any = useRef();
    const dispatcher = useDispatch();
    const [load, setLoad] = useState(false);
    const [userProfileUid, setUserProfileUid] = useState<any>();
    const [borderColor, setBorderColor] = useState('#d8d8d8'); 
    const [showErrorMessage, setShowErrorMessage] = useState(''); 
    const [captchaImageUrl, setCaptchaImageUrl] = useState<string>("");  // Store the CAPTCHA image URL
    const [captchaInput, setCaptchaInput] = useState<string>("");
    const[answer,setAnswer]=useState<string>("");
    // const cookies = new Cookies();
    // cookies.set("uId", userProfileUid);
    // const changeLoad = (value: boolean) => {
    //     setLoad(value);
    // };
    const [mobileNumber,setMobileNumber]=useState("")

    useEffect(()=>{
        googleAnalytics("LogIn_Screen")
    },[])
    //   const login = async (email: string, password: string) => {
    //     // signInFirebase(
    //     //   email,
    //     //   password,
    //     //   history,
    //     //   dispatcher,
    //     //   changeLoad,
    //     //   setUserProfileUid
    //     // );
    //     signInMongo(
    //       email,
    //       password,
    //       history,
    //       dispatcher,
    //       changeLoad,
    //       setUserProfileUid
    //     );
    //   };

    const [userIp, setUserIp] = useState<string>("");
    const [isdCode, setIsdCode] = useState<string>("");

    // Fetch the user's IP address and ISD code
    useEffect(() => {
        const fetchUserLocation = async () => {
            try {
                const response = await fetch('https://ipwhois.app/json/');
                const data = await response.json();
                setUserIp(data.ip);  // Store the IP address
                setIsdCode(data.country_code);  // Store the ISD code (country code)
                // console.log('User IP:', data.ip);
                // console.log('ISD Code:', data.country_code);
            } catch (error) {
                console.error('Error fetching location details:', error);
            }
        };
        fetchUserLocation();
    }, []);


    useEffect(()=>{
        createCaptcha(
            (value)=>{setCaptchaImageUrl(value)},
            (value)=>{setAnswer(value)},
            dispatcher
        );
    },[])
    const handleFocus = () => {
        setBorderColor('#279ea0'); // Change border color on focus
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setBorderColor('#279ea0'); // Reset border color if input is empty
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value:any = e.target.value;
        setMobileNumber(value);  // Update state with the input value
        setBorderColor(value === '' ? '#d8d8d8' : '#279ea0'); // Change border color
    };

    const handleRequestOtp = () => {
        localStorage.removeItem('timeLeft');
        if (mobileNumber.length===10 && mobileNumber.trim() !== '') {
            generateOtp(
                mobileNumber,
                history,
                dispatcher,
                (value=>{setShowErrorMessage(value)}),
                (value)=>{setCaptchaImageUrl(value)},
                (value)=>{setAnswer(value)},
                answer,
                captchaInput,
                userIp,
                (value)=>{setCaptchaInput(value)}
            );
        }
        else{
            dispatcher(
                showSnackbarAction(
                  'Please enter valid mobile number',
                  'error'
                )
              );
        }
    };

    const handleCaptchaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow numbers between 1 and 9, and the '-' symbol
        if (/^-?[0-9]*$/.test(value)) {
            setCaptchaInput(value);  // Update state with valid input
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow essential keys
        if (
            e.key === "Backspace" ||  // Allow backspace
            e.key === "Delete" ||     // Allow delete
            e.key === "ArrowLeft" ||  // Allow left arrow
            e.key === "ArrowRight" || // Allow right arrow
            e.key === "Tab"     ||      // Allow tab
            (e.ctrlKey && (e.key === 'v' || e.key === 'V')) // Allow Ctrl+C (case insensitive)
        ) {
            return; // Do nothing, allow the key event
        }
    
        // Prevent negative numbers, special characters, and non-digit inputs
        if (
            e.key === "-" ||           // Prevent minus sign
            e.key === "+" ||           // Prevent plus sign
            e.key === "e" ||           // Prevent exponent (e.g., in scientific notation)
            e.key === "." ||           // Prevent decimal point
            e.key === " " ||           // Prevent spaces
            (e.key < "0" || e.key > "9") 
        ) {
            e.preventDefault();
        }
    };
    

    return (
        <div
            className={styles.container}
        >
            <div className={styles.box1}>
                <img src={Logo} alt="Logo" className={styles.logo} />
                <img src={SignInImage} alt="Image" className={styles.imageFromBottom} />

            </div>
            <div className={styles.box2}>
                <div className={styles.box3}>
                    <span className={styles.WelcomeToReadPro}>
                        Welcome to
                        <span className={styles.textStyle1}> </span>
                        <span className={styles.textStyle2}>ReadPro</span>
                    </span>

                    <span className={styles.LogInYourAccount}>
                        Log in your account
                    </span>

                </div>
                <div className={styles.box4}>
                    <div className={styles.inputBox} style={{ borderColor }}>
                        <span className={styles.number}>+91</span>
                        <input
                            className={styles.inputSignIn}
                            type="number"
                            placeholder="Enter Mobile Number"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            value={mobileNumber}
                            maxLength={10}
                            minLength={10}
                            min={0}
                        />
                    </div>
                    <div className={styles.box7}>
                    <h6 className={styles.errorStyle}>{showErrorMessage}</h6>
                    </div>
                    <div className={styles.box6}>
                    
                    {captchaImageUrl ? (
                            <img
                                src={captchaImageUrl}
                                alt="CAPTCHA"
                                className={styles.captcha}
                            />
                        ) : (
                            <img src="" alt="CAPTCHA" className={styles.captcha}/>
                        )}
                        <img
                            src={RefreshCaptchaImage}
                            alt="Refresh CAPTCHA"
                            className={styles.captchaRefresh}
                            onClick={() => 
                               {createCaptcha(
                                (value) => setCaptchaImageUrl(value),
                                (value) => setAnswer(value),
                                dispatcher
                            )

                            setCaptchaInput("");} 
                            }
                        />
                        <input
                            className={styles.captcha}
                            type="text"
                            placeholder="Enter CAPTCHA"
                            value={captchaInput}
                            onChange={handleCaptchaChange}
                            style={{textAlign:"center",fontSize:"30"}}
                        />
                    </div>
                 
                    <button className={styles.button} style={{backgroundColor:mobileNumber.length===10?'#279f9f':'#d8d8d8'}} onClick={handleRequestOtp} disabled={mobileNumber.length===10 ?false:true}>
                      Request OTP
                    </button>
                </div>
                <div className={styles.loginViaEmail} onClick={()=>{
                    // history.replace("/signInEmail")
                    history.replace("/")
                }}>
                    <p>or Sign in Via Email</p>
                </div>
                <div className={styles.box5}>
                    <div className={styles.button2}>
                        <div className={styles.dow} >Download our App</div>
                        <a href="https://play.google.com/store/apps/details?id=com.readpro" target="_blank" style={{zIndex:100}}><img src={PlayStore} alt="playstore"/></a>
                        <div className={styles.line2}></div>
                        <a href="https://apps.apple.com/in/app/read-pro/id1566457452" target="_blank"><img src={AppStore} alt="AppStore" /></a>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default SignInOtp;
