import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import Reports from "../Screens/Reports/Reports";
import { Route, Redirect } from "react-router-dom";
import OrganizationPanel from "../Screens/OrganizationPanel/OrganizationPanel";
import AddUsers from "../Screens/AddUsers/AddUsers";
import { connect, useDispatch } from "react-redux";
import { fetchOrganizationsUsers, fetchOrganizationsUsersFromMongo } from "../Services/users";
import ApiPanel from "../Screens/ApiPanel/ApiPanel";
import NewsPanel from "../Screens/NewsPanel/NewsPanel";
import CustomButtonPanel from "../Screens/CustomButtonPanel/CustomButtonPanel";
import UpdatePassword from "../Screens/UpdatePassword/UpdatePassword";
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import organsationSettting from "../Screens/Settings/organsationSettting";
import helpModal from "../Components/Modals/help/helpModal";
import LeadManagerAnalytics from "../Screens/Analytics/LeadManagerAnalytics";


import UserPanel from "../Screens/UserPanel/UserPanel";
import Response from "../Screens/Response/Response";
import PropWiseResponse from "../Screens/Response/ResponsesPropertyWise";
import AddContacts from "../Screens/AddContacts/AddContacts";
import Task from "../Screens/TasksPanel/TaskPanel";
import Project from "../Screens/ProjectsPanel/ProjectsPanel";
import AddProject from "../Screens/AddProject/AddProject";
import AddTasks from "../Screens/AddTasks/AddTasks";
import Resources from "../Screens/Resources/Resources";
import ContactDetails from "../Screens/ContactDetails/ContactDetails";
import EditContact from "../Screens/EditContact/EditContact";

import CallLogsPanel from "../Screens/CallLogsPanel/CallLogsPanel";
import ProjectDetails from "../Screens/ProjectDetails/ProjectDetails";
import ProjectEditModal from "../Components/Modals/ProjectEditModal/ProjectEditModal";
import AddFAQ from "../Screens/AddFAQ/AddFAQ";
import FAQPanel from "../Screens/FAQPanel/FAQPanel";
import ApiDataPanel from "../Screens/ApiDataPanel/ApiDataPanel";
import LeadDistributor from "../Screens/LeadDistributor/LeadDistributor";
import "axios";
import axios from "axios";
import { setBranchUsersList } from "../Redux/actions";
import EditFAQ from "../Screens/EditFAQ/EditFAQ";
import MiniDrawer from "../Components/AnalyticsSideNav/SideNavBar";
import Booking from "../Screens/Booking/Booking";
import AddBooking from "../Screens/Booking/AddBooking";
import SubscriptionDetails from "../Screens/SubscriptionDetails/SubscriptionDetails";
import TransactionHistory from "../Screens/TransactionHistory/TransactionHistory";
import PaymentDetails from "../Screens/PaymentDetails/PaymentDetails";
import UpgradeUser from "../Screens/UpgradeUser/UpgradeUser";
import SubscriptionAlert from "../Components/SubscriptionAlert/SubscriptionAlert";
import AppUpdateAlert from "../Components/AppUpdateAlert/AppUpdateAlert";
import { boolean } from "yup";
import ReEnquiredApiDataPanel from "../Screens/ReEnquiredApiDataPanel/ReEnquiredApiDataPanel";
import InvoicePdf from "../Screens/PdfView/InvoicePdf";
import Settings from "../Screens/Settings/Settings";
import AuditLogs from "../Screens/AuditLogs/AuditLogs";
import Delete from "../Screens/DeleteLog/Delete";
import ReenquiredLeads from "../Screens/ReEnquiredLeads/ReenquiredLeads";
// import help from "../Screens/help/help";
import Integrations from "../Screens/Integrations/Integrations";
import moment from "moment";
import {fetchSingleMBData} from "../Services/contacts"

type props = {
  organizationId: string;
  history: any;
  authToken: string;
};

const OrganizationRoute: FunctionComponent<props> = ({
  organizationId,
  history,
  authToken
}) => {
  const dispatcher = useDispatch();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const contact_id = localStorage.getItem("contact_id");

  //     // console.log("VFCD","I rannnn1 1111")
      
  //     if (contact_id) {
  //       try {
  //         // Assuming fetchSingleMBData is an async function that fetches data
  //         const data = await fetchSingleMBData(contact_id);
  //         // console.log("contact_details data",data)
  //         // Navigate to /contactDetails and pass contact_id in state
  //         localStorage.removeItem("contact_id")
  //         const formatDate = (date:any) => {
  //           return date ? moment(date).format('DD MMM YY, LT') : '';
  //         };
  //         history.push({
  //           pathname: "/contactDetails",
  //           state: { detail:{  ...data,
  //             created_at: formatDate(data['created_at']),
  //             lead_assign_time: formatDate(data['lead_assign_time']),
  //             next_follow_up_date_time: formatDate(data['next_follow_up_date_time']),
  //             modified_at: formatDate(data['modified_at']),
  //             stage_change_at: formatDate(data['stage_change_at']),
  //             feedback_time: formatDate(data['feedback_time']),
  //             due_date: formatDate(data['due_date']),
  //           } } // You can also pass the fetched data if needed
  //         });
  //       } catch (error) {
  //         console.error("Error fetching contact data", error);
  //       }
  //     }
  //   };

  //   fetchData(); // Call the async function
  // }, [history]);
  useEffect(() => {
    
    if (organizationId) {
      // const unSub = fetchOrganizationsUsers(
      //   dispatcher,
      //   organizationId
      // );
      // return unSub;
      fetchOrganizationsUsersFromMongo(
        dispatcher,
        organizationId,
      );
    }
    // eslint-disable-next-line
  }, [authToken,organizationId]);

  return (
    <>
      <Navbar
        props={{ history }}
        title={"Users"}
        path={"/"}
        leadManger={false}
        api={"/apis"}
        news={"/news"}
        // customButton={"/customButton"}
      />
      <div className={styles.parent}>
        <AnalyticsSideNav
          props={{ history }}
          title={"Users"}
          path={"/"}
          leadManger={false}
          operationManger={false}
          api={"/apis"}
          news={"/news"}
          customButton={"/customButton"}
          updatePassword={"/updateUserPassword"}
          organsationSettting={"organsationSettting"}
          // help={"/help"}
          // mmb={"/mmb"} 
        />
        {/* <Route exact path="/" component={OrganizationPanel} /> */}
        <Route exact path="/">
          <Redirect to="/userManagement" />
        </Route>
        <Route exact path="/userManagement" component={OrganizationPanel} />
        <Route exact path="/contacts" component={UserPanel} />
        <Route exact path="/response" component={Response} />
        <Route exact path="/propwiseresponse" component={PropWiseResponse} />
        <Route path="/analytics" component={LeadManagerAnalytics} />
        {/* <Route path="/analytics" component={Analytics} /> */}
        <Route path="/reports" component={Reports} />
        <Route path="/apis" component={ApiPanel} />
        <Route path="/addUsers" component={AddUsers} />
        <Route path="/news" component={NewsPanel} />
        <Route path="/updateUserPassword" component={UpdatePassword} />
        {/* <Route
          path="/customButton"
          component={CustomButtonPanel}
        /> */}
        <Route path="/organsationSettting" component={organsationSettting} />
        <Route path="/help" component={helpModal} />
        {/* <Route exact path="/" component={UserPanel} /> */}
        <Route path="/reports" component={Reports} />
        <Route path="/addProjects" component={AddProject} />
        <Route path="/addTasks" component={AddTasks} />
        <Route path="/contactDetails" component={ContactDetails} />
        <Route path="/tasks" component={Task} />
        <Route path="/projects" component={Project} />
        <Route path="/resources" component={Resources} />
        <Route path="/addContacts" component={AddContacts} />
        <Route path="/editContacts" component={EditContact} />
        <Route path="/callLogs" component={CallLogsPanel} />
        <Route path="/projectDetails" component={ProjectDetails} />
        <Route path="/editProjects" component={ProjectEditModal} />
        <Route path="/addFAQ" component={AddFAQ} />
        <Route path="/editFAQ" component={EditFAQ} />
        <Route path="/faqs" component={FAQPanel} />
        <Route path="/leadDistributor" component={LeadDistributor} />
        <Route path="/apiData" component={ApiDataPanel} />
        <Route path="/integrations" component={Integrations} />
        <Route path="/booking" component={Booking} />
        <Route path="/addBooking" component={AddBooking} />
        <Route path="/viewBookingDetails" component={AddBooking} />
        <Route path="/subscriptionDetails" component={SubscriptionDetails} />
        <Route path="/settings" component={Settings} />
        <Route path="/auditLogs" component={AuditLogs} />
        <Route path="/help" component={helpModal}/>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    authToken:state.user.authToken,
  };
};

export default connect(mapStateToProps)(OrganizationRoute);
