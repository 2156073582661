import React,{useEffect,useState} from "react";
import { Route ,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import SuperAdminRoute from "./SuperAdminRoute";
import OrganizationRoute from "./OrganizationRoute";
import UserRoute from "./UserRoute";
import LeadManagerRoute from "./LeadManagerRoute";
import DrilldownDataRoute from "./DrilldownDataRoute";
import OrganizationManagerRoute from "./OrganizationManagerRoute";
import { firestore, functions } from "../Firebase";
import { getDateString,getDateIsoString,compareDates,getExpirationDate,sendMail,getDateString2 } from "../Values/utils";
import { setDeactivationAlert,setOrganizationData,setAuthToken, setAnalyticsFilterObject, setCallRecordingStatus, setShowMMBBucket } from "../Redux/actions";
import { useDispatch } from "react-redux";
import {handleUser,handlePaymentStatus,handlePaymentStatus1} from "../Services/auth";
import { updateUserPreference } from "../Services/resources";
import { auth } from "../Firebase";
import LogOutModal from "../Components/Modals/LogOutModal/LogOutModal";
import { url } from "../Values/constants";
import axios from 'axios';
import axiosHandler from "../Services/axiosHandler";
import {fetchOrgResMongo} from "../Services/resources"
import { fetchOrganizationData, orgHadCallRecordingSubscription, showMMBBucket } from "../Services/organizations";
import ForceMobileUpdateModal from "../Components/Modals/ForceMobileUpdateModal/ForceMobileUpdateModal";
import ContactDetails from "../Screens/ContactDetails/ContactDetails";
import moment from "moment";
import {fetchSingleMBData} from "../Services/contacts"

const HomeRoute = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let path = props.history.location.pathname;
  console.log("rishabh contacts ",props.contact_id)
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [usersEmail, setUsersEmail] = useState<any[]>([]);
  const [openMobileUpdateModal, setOpenMobileUpdateModal] = useState(false);

  // const [contId,setContId]=useState(cookie.get())
  
  useEffect(() => {
    const fetchData = async () => {
      // const contact_id = localStorage.getItem("contact_id");

      // console.log("VFCD","I rannnn1 1111")
      const contactId=localStorage.getItem("contact_id")
      
      if (props.contact_id || contactId) {

        try {
          // Assuming fetchSingleMBData is an async function that fetches data
          const data = await fetchSingleMBData((props.contact_id || contactId));
          // console.log("contact_details data",data)
          // Navigate to /contactDetails and pass contact_id in state
          localStorage.removeItem("contact_id")
          const formatDate = (date:any) => {
            return date ? moment(date).format('DD MMM YY, LT') : '';
          };
          history.replace({
            pathname: "/contactDetails",
            state: { detail:{  ...data,
              created_at: formatDate(data['created_at']),
              lead_assign_time: formatDate(data['lead_assign_time']),
              next_follow_up_date_time: formatDate(data['next_follow_up_date_time']),
              modified_at: formatDate(data['modified_at']),
              stage_change_at: formatDate(data['stage_change_at']),
              feedback_time: formatDate(data['feedback_time']),
              due_date: formatDate(data['due_date']),
              contactId:data['Id']
            } } // You can also pass the fetched data if needed
          });
        } catch (error) {
          console.error("Error fetching contact data", error);
          history.replace("/contacts")
        }
      }
    };

    fetchData(); // Call the async function
  }, [props.contact_id,props.userId]);

  useEffect(()=>{
    let organizationId = props.user.organization_id;
    let uid = props.user.uid;
    showMMBBucket(organizationId,uid).then(async (data) => {
      dispatch(setShowMMBBucket({ showMigratedBucket: data }));
    });
  },[props.user.organization_id])


  // const logOut = async () => {
  //   Object.keys(props.contacts.data).forEach((key) => {
  //     props.contacts.data[key] = [];
  //   });
  //   await auth.signOut();
  //   props.history.replace("/", {});
  // };

  const updatePlan = (organizationId:any, value: any) => {
    let docRef = firestore.collection("organizations").doc(organizationId);
    docRef.update({
      payment_status: value
    })
    .then(() => {
        console.log("Document successfully updated!");
    })
    .catch((error) => {
        console.error("Error updating document: ", error);
    });
  }

  useEffect(() => {
      if (props.authToken && props.user.organization_id) {
        // console.log("porpp",props.constants.showMobileUpdationModal,props.user.is_mobile_updation_declared);
        if(props.constants.showMobileUpdationModal && props.user.is_mobile_updation_declared !== true){
          setOpenMobileUpdateModal(true);
        }
        updateUserPreference(props.authToken, dispatch,props.user.uid,props.user.organization_id);
      }
  }, [props.authToken,props.user.profile,props.user.organization_id,props.constants.showMobileUpdationModal])


  useEffect(() => {
    let organizationId = props.user.organization_id;
    
    fetchOrganizationData(organizationId).then(async (data) => {
      let is_call_recording_enabled = data.is_call_recording_enabled ? data.is_call_recording_enabled : false;
      let is_call_recording_subscribed = data.is_call_recording_subscribed ? data.is_call_recording_subscribed : false;
      let organizationHadCallRecordingSubscription = false;
      let show_call_recording_enable_option = false;
      organizationHadCallRecordingSubscription  = await orgHadCallRecordingSubscription(organizationId);
      if(is_call_recording_subscribed && (props.user.profile === "Lead Manager" || props.user.profile === "Admin")){
        show_call_recording_enable_option = true;
      }else if(is_call_recording_subscribed == false && (props.user.profile === "Lead Manager" || props.user.profile === "Admin") && organizationHadCallRecordingSubscription){
        show_call_recording_enable_option = true;
      }
      dispatch(setOrganizationData({ organizationData: data }));
      dispatch(setCallRecordingStatus({ is_call_recording_enabled,is_call_recording_subscribed,show_call_recording_enable_option }));
    });
  }, [props.user, path]);
  
  // useEffect(() =>{
  //   let organizationId = props.user.organization_id
    
  //   const data = fetchOrganizationData(organizationId )
  //           dispatch(setOrganizationData({organizationData:data}));
            
  // },[
  //   props.user,path
  // ])

  // useEffect(() =>{
  //   let organizationId = props.user.organization_id
  //   let docRef = firestore.collection("organizations").doc(organizationId);
  //   try{
  //     docRef.get().then((doc) => {
  //       if (doc.exists) {
  //           let data:any = doc.data();
  //           dispatch(setOrganizationData({organizationData:data}));
  //           let gracePeriod = Number(data.grace_period);
  //           let validTill = getDateIsoString(data.valid_till);
  //           let validTillDate = getDateString2(data.valid_till);
  //           let expirationDate = getExpirationDate(validTill,gracePeriod);
  //           let currentDate = new Date().toISOString().slice(0, 10);
  //           let isDeactivated = compareDates(currentDate,expirationDate);
  //           let paymentStatus = data.payment_status;
  //           let mailId = data.org_mail_id;
  //           let orgName = data.organization_name;
  //           if(isDeactivated) {
  //             console.log("in the mail block")
  //             // const mailRequest = `<div>Hello,</div> <br/><div>Readpro licenses of ${orgName} has expired and all their users have been disabled.</div> <br/>  <div>Please reach out to them as soon as possible.</div>  <br/>`
  //             // const mailRequestUser = `<div>Hello,</div> <br/><div>Readpro licenses of your organization has expired and all of your users have been disabled.</div> <br/>  <div>Please renew your Readpro subscription to continue using our services.</div>  <br/>`
  //             // sendMail(mailRequest,["helpdesk@itsforyou.live","accounts@itsforyou.live"],"Account deactivated");
  //             // sendMail(mailRequestUser,mailId,"Account deactivated");
  //             updatePlan(organizationId,false);
  //           }else{
  //             if(isDeactivated === false){
  //               updatePlan(organizationId,true);
  //             }
  //             let isLicenseInactive = compareDates(currentDate,validTill);
  //             if(isLicenseInactive){
  //               dispatch(setDeactivationAlert({showAlert:isLicenseInactive,expirationDate:expirationDate,validTill:validTillDate}))
  //             }else{
  //               dispatch(setDeactivationAlert({showAlert:isLicenseInactive,expirationDate:expirationDate,validTill:validTillDate}))
  //             }
  //           }
  //       } else {
  //           console.log("No such document!");
  //       }
  //     })
  //   }catch(err){
  //     console.log(err);
  //   }
  // },[
  //   props.user,path
  // ])


  // useEffect(()=>{
  //   if(props.userStatus){
  //     let authTokenRef = firestore.collection("values").doc("constants");
  //     authTokenRef.get().then((doc) => {
  //         if (doc.exists) {
  //           let authToken = doc.data()?.authToken
  //             dispatch(setAuthToken({authToken: authToken}))
  //         } else {
  //           dispatch(setAuthToken({authToken: null}))
  //         }
  //     }).catch((error) => {
  //       dispatch(setAuthToken({authToken: null}))
  //     });
  //   }
  // },[props.userStatus])

  useEffect(() => {
    let unSub:any;

    const handleEffect = async () => {
      if (props.user.uid) {
        unSub = await handlePaymentStatus1(props.user.organization_id, props.user, dispatch);
        // updateUserPreference(props.authToken, dispatch,props.user.uid,props.user.organization_id);
      }
    };

    handleEffect();

    return () => {
      if (unSub) {
        unSub(); // Ensure unSub is called during cleanup
      }
    };
  }, [
    props.user.uid
  ]);

  
  // useEffect(() => {
  //   if (props.user.uid) {
  //     handleUser(props.user.uid, props.user, dispatch,setOpenLogoutModal);
  //   }
  // }, [
  //   props.user.uid
  // ]);
  useEffect(() => {
    if (props.organizationUsers) {
      let l: any[] = [];
      const users: { [key: string]: string } = {};

      props.organizationUsers.map((user: any) => {
        users[user.uid] = user.user_first_name + ' ' + user.user_last_name;
        l.push(user.user_email);
      });

      // setUsersList(users);
      setUsersEmail(l);
    }
  }, [props.organizationUsers]);

    function option(a: { label: any } | string, b: { label: any } | string): number {
    if (typeof a === 'string' && typeof b === 'string') {
      // Handle the case where both a and b are strings
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
      
      return 0;
    } else if (typeof a === 'object' && typeof b === 'object') {
      // Handle the case where both a and b are objects with a 'label' property
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
    
      return 0;
    } else {
      return 0; 
    }
  }

  const getContactsFilter = async () => {
    let finalFilter: { [key: string]: any } = {};
    // axios.defaults.headers.common['x-access-token'] = props.authToken
    const res = await axiosHandler.post(url + '/leads/filterValues', {
      uid: props.user.uid,
    });
    let l: any[] = [];
    if (res.data[0]) {
      l = Object.keys(res.data[0]);
      finalFilter = res.data[0];
    }
    // axios.defaults.headers.common['x-access-token'] = props.authToken
    const taskRes = await axiosHandler.post(url + '/tasks/filterValues', {
      uid: props.user.uid,
    });

    let tasks: { [key: string]: [] } = {};
    if (taskRes.data[0]) {
      Object.keys(taskRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          tasks[key] = taskRes.data[0][key];
          l.push(key);
        }
      });
    }

    // axios.defaults.headers.common['x-access-token'] = props.authToken
    const callRes = await axiosHandler.post(url + '/callLogs/filterValues', {
      uid: props.user.uid,
    });
    const userdetail = await axiosHandler.post(url + "/users/fetchSpecificData", {
      organization_id: props.user.organization_id,
      uid: props.user.uid,
      });
    let calls: { [key: string]: [] } = {};
    if (callRes.data[0]) {
      Object.keys(callRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          calls[key] = callRes.data[0][key];
          l.push(key);
        }
      });
    }
    let otherFilters = {
      transfer_status:["True","False"],
      lead_type:['Data','Leads'],
      reporting_to: usersEmail,
      previous_owner_1: usersEmail,
      previous_owner_2: usersEmail,
      transfer_by_1: usersEmail,
      transfer_by_2: usersEmail,
      previous_stage_1: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      previous_stage_2: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      status: ['Completed', 'Pending', 'Overdue'],
      employee_name:userdetail.data.data.Employee_Name,
      employee_id:userdetail.data.data.Employee_id
    };

    finalFilter = { ...finalFilter, ...tasks, ...calls, ...otherFilters };

    Object.keys(finalFilter).forEach((val:any)=>{

      if(val==="employee_id" || val==="employee_name"){
        finalFilter[val]=finalFilter[val].sort(option);
      }
      else{
        finalFilter[val]=finalFilter[val].sort();
      }
  })
  dispatch(setAnalyticsFilterObject(finalFilter));
    // setFilterValue(finalFilter);
  };

  useEffect(() => {
    if (props.user  && props.authToken) {
      getContactsFilter();
    }
  }, [props.user, usersEmail,props.authToken]);
  useEffect(()=>{
      if(props.user.uid){
        fetchOrgResMongo(props.user.organization_id,dispatch)
      }
  },[props.user])

  // useEffect(() => {
  //   const startTime = performance.now();
  //   const image = new Image();
  //   image.src = `https://i.imgur.com/rc8vtVX.jpg?time=${Date.now()}`;
  //   image.onload = () => {
  //     const endTime = performance.now();
  //     let timeTaken = endTime - startTime;
  //     if(timeTaken > 2000){
  //       alert("Your network speed is slow, Please switch to a better connection for improved performance");
  //     }
  //   };
  // }, []);




  return (
    <div>
      <LogOutModal
        openModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
      />
      {/* <ForceMobileUpdateModal
        open={openMobileUpdateModal}
        close={() => setOpenMobileUpdateModal(false)}
      /> */}
      {String(props.history.location.pathname).startsWith("/resetpassword") !==
        true &&
        props.userStatus && (
          <>
            {props.userStatus === true && (
              <>
                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
              ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/taskDrilldownData"
                  ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/callDrilldownData"
                  ) !== true && (
                    <>
                    
                      {props.userRole === "superAdmin" && (
                        <Route component={SuperAdminRoute} />
                      )}
                      {props.userRole === "Operation Manager" && (
                        <Route component={OrganizationManagerRoute} />
                      )}
                      {props.userRole === "organization" && (
                        <Route component={OrganizationRoute} />
                      )}
                      {props.userRole !== "organization" &&
                        props.userRole !== "superAdmin" &&
                        props.userRole !== "Lead Manager" &&
                        props.userRole !== "Operation Manager" &&(
                          <Route component={UserRoute} />
                        )}
                      {props.userRole === "Lead Manager" && (
                        <Route component={LeadManagerRoute} />
                      )}
                    </>
                  )}

                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
                ) === true && (
                  <Route path="/drilldownData" component={DrilldownDataRoute} />
                )}
                {String(props.history.location.pathname).startsWith(
                  "/taskDrilldownData"
                ) === true && (
                  <Route
                    path="/taskDrilldownData"
                    component={DrilldownDataRoute}
                  />
                )}
                {String(props.history.location.pathname).startsWith(
                  "/callDrilldownData"
                ) === true && (
                  <Route
                    path="/callDrilldownData"
                    component={DrilldownDataRoute}
                  />
                )}
              </>
            )}
          </>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    user: state.user.data,
    authToken: state.user.authToken,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    constants:state.constants.data,
  };
};

export default connect(mapStateToProps)(HomeRoute);
