import React, { FunctionComponent, useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import './booking.style.css';
import { Button } from "@mui/material";
import { BsPlus } from 'react-icons/bs';
import FormDialog from "./Dialog";
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import MenuIcon from '@mui/icons-material/Menu';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import axiosHandler from "../../Services/axiosHandler";
import { googleAnalytics, googleAnalyticsCTAClick } from "../../Services/users";

const OMColumns: GridColDef[] = [
  { field: 'id', headerName: 'S.NO', width: 60, align: "center" },
  // { field: 'booking_id', headerName: 'Booking Id', width: 100 },
  { field: 'status', headerName: 'Status', width: 120 },
  { field: 'customer_name', headerName: 'Customer Name', width: 130, },
  { field: 'developer_name', headerName: 'Developer Name', width: 132 },
  { field: 'project_name', headerName: 'Project Name', width: 115 },
  { field: 'contact_no', headerName: 'Contact No', width: 110 },
  { field: 'pan_card', headerName: 'Pan Number', width: 120 },
  { field: 'area', headerName: 'Area', width: 60 },
  {
    field: 'date_booking',
    headerName: 'Booking Date',
    width: 115,
    valueGetter: (params: GridValueGetterParams) =>
      `${moment(params.row.date_booking).format("YYYY-MMM-DD")}`,
  },
  { field: 'area_type', headerName: 'Area Type', width: 100 },
  { field: 'unit_no', headerName: 'Unit No', width: 80 },
  { field: 'source_fund', headerName: 'Source Fund', width: 120 },
  { field: 'scheme', headerName: 'Applicant Scheme', width: 160 },
  {
    field: "owner_email",
    headerName: "Owner Email",
    width: 120,
    valueGetter: (params) => params.row?.contact_owner_email,
  },
  {
    field: "property_details_BSP.tower_block",
    headerName: "Tower Block",
    width: 120,
    type: "string",
    valueGetter: (params) => params.row?.property_details_BSP?.tower_block,
  },
  {
    field: "property_details_BSP.payment_plan",
    headerName: "Payment Plan",
    width: 140,
    type: "string",
    valueGetter: (params) => params.row?.property_details_BSP?.payment_plan,
  },
  {
    field: "property_details_BSP.bsp",
    headerName: "BSP",
    width: 120,
    type: "string",
    valueGetter: (params) => params.row?.property_details_BSP?.bsp,
  },
  {
    field: "property_details_BSP?.bsp_discounting_type",
    headerName: "BSP Discounting Type",
    width: 200,
    type: "string",
    valueGetter: (params) => params.row?.property_details_BSP?.bsp_discounting_type,
  },
  {
    field: "property_details_BSP?.bsp_discount_amount",
    headerName: "BSP Discount Amount",
    width: 200,
    type: "string",
    valueGetter: (params) => params.row?.property_details_BSP?.bsp_discount_amount,
  },
  {
    field: "consolidated_costing?.gst_amount",
    headerName: "GST Amount",
    width: 160,
    type: "string",
    valueGetter: (params) => params.row?.consolidated_costing?.gst_amount,
  },
  {
    field: "consolidated_costing?.minimum_booking_amount",
    headerName: "Minimum Booking Amount",
    width: 220,
    type: "string",
    valueGetter: (params) => params.row?.consolidated_costing?.minimum_booking_amount,
  },
  {
    field: "source_of_fund?.funding_source",
    headerName: "Funding Source",
    width: 180,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.funding_source,
  },
  {
    field: "source_of_fund?.gross_monthly_income",
    headerName: "Gross Monthly Income",
    width: 220,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.gross_monthly_income,
  },
  {
    field: "source_of_fund?.rental_income",
    headerName: "Rental Income",
    width: 160,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.rental_income,
  },
  {
    field: "source_of_fund?.Occupation",
    headerName: "Occupation",
    width: 140,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.Occupation,
  },
  {
    field: "source_of_fund?.annual_bonus_incentive",
    headerName: "Annual Bonus / Incentive",
    width: 240,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.annual_bonus_incentive,
  },
  {
    field: "source_of_fund?.existing_EMI",
    headerName: "Existing EMI",
    width: 180,
    type: "string",
    valueGetter: (params) => params.row?.source_of_fund?.existing_EMI,
  },
  {
    field: "additional_charges?.a",
    headerName: "A",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.a,
  },
  {
    field: "additional_charges?.b",
    headerName: "B",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.b,
  },
  {
    field: "additional_charges?.c",
    headerName: "C",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.c,
  },
  {
    field: "additional_charges?.d",
    headerName: "D",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.d,
  },
  {
    field: "additional_charges?.e",
    headerName: "E",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.e,
  },
  {
    field: "additional_charges?.f",
    headerName: "F",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.f,
  },
  {
    field: "additional_charges?.g",
    headerName: "G",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.g,
  },
  {
    field: "additional_charges?.h",
    headerName: "H",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.h,
  },
  {
    field: "additional_charges?.i",
    headerName: "I",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.i,
  },
  {
    field: "additional_charges?.a_amount",
    headerName: "A Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.a_amount,

  },
  {
    field: "additional_charges?.b_amount",
    headerName: "B Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.b_amount,
  },
  {
    field: "additional_charges?.c_amount",
    headerName: "C Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.c_amount,
  },
  {
    field: "additional_charges?.d_amount",
    headerName: "D Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.d_amount,
  },
  {
    field: "additional_charges?.e_amount",
    headerName: "E Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.e_amount,
  },
  {
    field: "additional_charges?.f_amount",
    headerName: "F Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.f_amount,
  },
  {
    field: "additional_charges?.g_amount",
    headerName: "G Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.g_amount,
  },
  {
    field: "additional_charges?.h_amount",
    headerName: "H Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.h_amount,
  },
  {
    field: "additional_charges?.i_amount",
    headerName: "I Amount",
    width: 100,
    type: "string",
    valueGetter: (params) => params.row?.additional_charges?.i_amount,
  }
 
];
const columns: GridColDef[] = [
  { field: 'id', headerName: 'S.NO', width: 60, align: "center" },
  { field: 'status', headerName: 'Status', width: 120 },
  // { field: 'booking_id', headerName: 'Booking Id', width: 90 },
  { field: 'customer_name', headerName: 'Customer Name', width: 130, },
  { field: 'developer_name', headerName: 'Developer Name', width: 132 },
  { field: 'project_name', headerName: 'Project Name', width: 115 },
  { field: 'contact_no', headerName: 'Contact No', width: 110 },
  { field: 'pan_card', headerName: 'Pan Number', width: 120 },
  { field: 'area', headerName: 'Area', width: 60 },
  {
    field: 'date_booking',
    headerName: 'Booking Date',
    width: 115,
    valueGetter: (params: GridValueGetterParams) =>
      `${moment(params.row.date_booking).format("YYYY-MMM-DD")}`,
  },
  { field: 'area_type', headerName: 'Area Type', width: 100 },
  { field: 'unit_no', headerName: 'Unit No', width: 80 },
  { field: 'source_fund', headerName: 'Source Fund', width: 120 }
  // { field: 'scheme', headerName: 'Scheme', width: 130 },
  // { field: 'booking_attachment', headerName: 'Booking Attachment', width: 130 },
  // { field: 'Kyc_attachment', headerName: 'Kyc Attachment', width: 130 },

];
type props = {
  history: any;
  user: any;
  theme: any;
  authToken:any;
};
const Booking: FunctionComponent<props> = ({
  history,
  user,
  theme,
  authToken
}) => {
  const dispatcher = useDispatch();
  const [bookigDetails, setBookingDetails] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [load, setLoad] = useState(false);
  const [isGACalled, setIsGACalled] = useState<any>(false);


  useEffect(() => {
    if (user.uid) {
      if(authToken){
        getAllBooKing();
      }
    }
  }, [user,authToken])
  const getAllBooKing = async () => {
    let filterData: any[] = [];

    const bodyData = {
      filter: {},
      page: 1,
      pageSize: 40,
      searchString: "",
      sort: {},
      uid: user?.uid,
      bookingFilter: {},
      skip: 0,
      limit: 40
    }
    try {
      setLoad(true);
//       axios.defaults.headers.common['x-access-token'] =
// authToken
      const getBookingRes = await axiosHandler.post(
        url + '/bookings/booking',
        bodyData
      );
      getBookingRes?.data?.map((val: any, index: any) => {
        return filterData.push(
          ...val.bookingDetails?.map((list: any) => {
            return { "organization_id": val.organization_id, "uid": val.uid, "customer_name": val.contactDetails?.customer_name, "contact_owner_email": val.contactDetails?.contact_owner_email,"Contact_Id": val.contactDetails?.contactId, "contact_no": val.contact_no, ...list }
          })
        )
      })
      const filterAllBookingData = filterData?.map((va: any, index: any) => {
        return { "id": index + 1, ...va }
      })
      setBookingDetails(filterAllBookingData);
      setLoad(false);
    }
    catch {
      setLoad(false);
      dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
    }

  }
  const handleOnCellClick = (params: any) => {
    if (user?.profile === "Operation Manager") {
      history.push({
        pathname: '/editBooking',
        state: { "paramsData": params?.row}
      })
    }
    else {
      history.push({
        pathname: '/viewBookingDetails',
        state: { "data": params?.row, "key": "view" }
      })
    }
  };
  const containerStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  const tdStyles = {
    backgroundColor: theme ? themeColors.tdBackgroundColor : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  }
  const thStyles = {
    backgroundColor: theme ? themeColors.thBackgroundColor : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark
  }
  useEffect(()=>{
    if(isGACalled === false){
      if(user?.uid && user?.profile){
        setIsGACalled(true)
        googleAnalytics('Booking_Screen',user)
      }
    }
  },[user])

  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "0px", ...containerStyles }}>
        {load === true && <Loading />}
        {user?.profile === "Operation Manager" ? <div
          className="box"
        ></div> : <> <div
          className="box"
        >
          <button
            className="addBox"
            onClick={() => { 
              googleAnalyticsCTAClick("Add Re-Booking Button","Booking Screen",user)
              setOpen(true) }}
          >
            <BsPlus size={22} color={'#ffffff'} />
            {"Add Re-Booking"}
          </button>
        </div>
        </>}
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          <div className="booking-table" style={{ height: 600, width: '100%', ...tdStyles }}>
            <DataGrid
              rows={bookigDetails}
              columns={user?.profile === "Operation Manager" ? OMColumns : columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              onCellClick={handleOnCellClick}
              style={tdStyles}
              // disableSelectionOnClick
              components={{
                ColumnMenuIcon: ListSharpIcon
              }}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  color: theme ? "black" : "white",
                },
                ".css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled": {
                  color: theme ? "black" : "white",
                },
                "& .MuiDataGrid-columnHeaders": {
                  ...thStyles,
                },
                ".MuiDataGrid-sortIcon": {
                  color: theme ? "black" : "white"
                },
                ".MuiDataGrid-iconSeparator": {
                  // color: theme ? "black" : "white",
                  visibility: 'hidden',
                },
                // ".css-ptiqhd-MuiSvgIcon-root": {
                //   color: theme ? "black" : "white",
                // },
                ".MuiDataGrid-menuIconButton": {
                  color: theme ? "black" : "white"
                },
                ".MuiIconButton-colorInherit":{
                  color: theme ? "black" : "white"
                },
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
              }}
            // checkboxSelection
            />
          </div>
        </div>
      </div>
      {open && <FormDialog
        openDialog={open}
        setOpenDialog={setOpen}
        userDetails={user}
        history={history}
      />}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    theme: state.theme.isLightMode,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
    authToken:state.user.authToken,
  };
};

export default connect(mapStateToProps)(Booking);
