import React, { useState, useEffect } from 'react';
import styles from './Analytics.module.css';
import {
  AiFillPieChart,
  AiOutlineBarChart,
  AiOutlineDotChart,
} from 'react-icons/ai';
import { FiBarChart2, FiBarChart } from 'react-icons/fi';
import { BiBarChart } from 'react-icons/bi';
import { BsGraphUp } from 'react-icons/bs';
import { FaFilter } from 'react-icons/fa';
import CountBox from '../../Components/CountBox/CountBox';
import { Bar, Line, Pie } from 'react-chartjs-2';
import AnalyticsFilter from '../../Components/AnalyticsFilter/AnalyticsFilter';
import { functions } from '../../Firebase';
import { connect } from 'react-redux';
import AnalyticsGraph from '../../Components/AnalyticsGraph/AnalyticsGraph';
import axios from 'axios';
import Loading from '../../Components/Loading/Loading';
import moment from 'moment';
import Firebase from 'firebase/app';
import { url } from '../../Values/constants';
import IntBarTable from '../../Components/IntBarTable/IntBarTable';
import commonStyles from '../../CommonStyles/CommonGraphStyles.module.css';
import {themeColors} from '../../Components/theme';
import axiosHandler from '../../Services/axiosHandler';
import { googleAnalytics } from '../../Services/users';
const Analytics = ({ props, user, history,theme,authToken,showMigratedBucket }: any) => {
  const [showFilter, setShowFilter] = useState(false);
  const [callLogAnalytics, setCallLogAnalytics] = useState({});
  const [updateData, setUpdateData] = useState(false);
  const [leadAnalytics, setLeadAnalytics] = useState<any>({
    budget: {},
    location: {},
    project: {},
    propertyStage: {},
    propertyType: {},
    stage: { Completed: 0, Pending: 0 },
  });
  const [taskAnalytics, setTaskAnalytics] = useState({});
  const [overdueAnalytics, setOverdueAnalytics] = useState({});
  const [pendingAnalytics, setPendingAnalytics] = useState({});
  const [interestedTrendAnalytics, setInterestedTrendAnalytics] = useState({});
  const [followUp, setFollowUp] = useState([]);
  const [stage, setStage] = useState({
    INTERESTED: 0,
    FRESH: 0,
    CALLBACK: 0,
    WON: 0,
    LOST: 0,

    'NOT INTERESTED': 0,
    'MIGRATED LEADS': 0,
  });
  const [callLogData, setCallLogData] = useState<{ data: any[]; label: any[] }>(
    { data: [], label: [] }
  );
  const [interestedData, setInterestedData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [budgetData, setBudgetData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [locationData, setLocationData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [projectData, setProjectData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [propertyStageData, setPropertyStageData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [propertyTypeData, setPropertyTypeData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [overdueData, setOverdueData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [scheduledData, setScheduledData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [completedData, setCompletedData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [filterType, setFilterType] = useState<
    'All' | 'YTD' | 'PM' | 'MTD' | 'T' | 'Y'
  >('All');
  const [apiBudgetTableData, setApiBudgetTableData] = useState();
  const [apiLocationTableData, setApiLocationTableData] = useState();
  const [apiProjectTableData, setApiProjectTableData] = useState();
  const [load, setLoad] = useState(false);
  const [scheduledTaskCount, setScheduledTaskCount] = useState(0);
  const [completedTaskCount, setCompletedTaskCount] = useState(0);
  const [panel, setPanel] = useState('Contacts');
  const [isGACalled, setIsGACalled] = useState<any>(false);
  const [apiCallLogAnalyticsData, setCallLogApiAnalyticsData] = useState<any>(
    {}
  );

  const callApi = async () => {
    setLoad(true);
    let startDate;
    let endDate;

    if (filterType === 'MTD') {
      const startOfMonth = moment().startOf('month').toDate();
      startDate = startOfMonth;
      endDate = Firebase.firestore.Timestamp.now().toDate();
    } else if (filterType === 'PM') {
      const prevMonth = moment().subtract(1, 'M');
      const prevMonthStart = prevMonth.startOf('month').toDate();
      const prevMonthEnd = prevMonth.endOf('month').toDate();
      startDate = prevMonthStart;
      endDate = prevMonthEnd;
    } else if (filterType === 'T') {
      const todayStart = moment().startOf('day').toDate();
      const todayEnd = moment().endOf('day').toDate();
      startDate = todayStart;
      endDate = todayEnd;
    } else if (filterType === 'Y') {
      const prevDay = moment().subtract(1, 'day');
      const prevDayStart = prevDay.startOf('day').toDate();
      const prevDayEnd = prevDay.endOf('day').toDate();
      startDate = prevDayStart;
      endDate = prevDayEnd;
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const callLogsApiData = {
      uid: user.uid,
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const callLogsRes = await axiosHandler.post(
      url + '/callLogs/callingReport',
      callLogsApiData
    );

    if (callLogsRes.data) {
      const obj = Object.assign({}, ...callLogsRes.data.ChartCount);
      let callsData: { [key: string]: { callLogAnalytics: {} } } = {};
      callLogsRes.data.report.forEach((item: any) => {
        let data: {
          '0 sec': number;
          '0-30 sec': number;
          '31-60 sec': number;
          '61-120 sec': number;
          '>120 sec': number;
        } = { '0 sec': 0, '0-30 sec': 0, '31-60 sec': 0, '61-120 sec': 0, '>120 sec': 0 };
        item.duration.forEach((val: any) => {
          if (val.duration === 0) {
            data['0 sec'] = val.count;
          } else if (val.duration === 30) {
            data['0-30 sec'] = val.count;
          } else if (val.duration === 60) {
            data['31-60 sec'] = val.count;
          } else if (val.duration === 120) {
            data['61-120 sec'] = val.count;
          } else {
            data['>120 sec'] = val.count;
          }
        });
        callsData[item.created_at] = { callLogAnalytics: data };
      });

      setCallLogApiAnalyticsData(callsData);

      setCallLogAnalytics(obj);
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const interestedTrendApiData = {
      uid: user.uid,
      parameter: 'stage_change_at',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const interestedTrendRes = await axiosHandler.post(
      url + '/leads/interestedReport/associate',
      interestedTrendApiData
    );

    if (interestedTrendRes.data.ChartCount) {
      const obj = Object.assign({}, ...interestedTrendRes.data.ChartCount);

      setInterestedTrendAnalytics(obj);
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const feedbackApiData = {
      uid: user.uid,
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const feedbackRes = await axiosHandler.post(
      url + '/leads/feedbackReport/associate',
      feedbackApiData
    );

    if (feedbackRes.data) {
      setStage(feedbackRes.data.ChartCount);
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const budgetApiData = {
      uid: user.uid,
      parameter: 'budget',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const budgetRes = await axiosHandler.post(
      url + '/leads/interestedReport/associate',
      budgetApiData
    );

    if (budgetRes.data) {
      setBudgetData({
        data: Object.values(budgetRes.data.ChartCount),
        label: Object.keys(budgetRes.data.ChartCount),
      });
      setApiBudgetTableData(budgetRes.data.ChartCount);
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const locationApiData = {
      uid: user.uid,
      parameter: 'location',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const locationRes = await axiosHandler.post(
      url + '/leads/interestedReport/associate',
      locationApiData
    );

    if (locationRes.data) {
      setLocationData({
        data: Object.values(locationRes.data.ChartCount),
        label: Object.keys(locationRes.data.ChartCount),
      });
      setApiLocationTableData(locationRes.data.ChartCount);
    }
//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const projectApiData = {
      uid: user.uid,
      parameter: 'project',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const projectRes = await axiosHandler.post(
      url + '/leads/interestedReport/associate',
      projectApiData
    );

    if (projectRes.data) {
      setProjectData({
        data: Object.values(projectRes.data.ChartCount),
        label: Object.keys(projectRes.data.ChartCount),
      });
      setApiProjectTableData(projectRes.data.ChartCount);
    }

//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const completedTaskApiData = {
      uid: user.uid,
      parameter: 'type',
      status: 'Completed',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const completedTaskRes = await axiosHandler.post(
      url + '/tasks/tasksReport/associate',
      completedTaskApiData
    );

    let completedCount = 0;
    if (completedTaskRes.data) {
      let obj: {
        [key: string]: {
          Completed: {
            'Call Back': number;
            'Site Visit': number;
            Meeting: number;
          };
        };
      } = {};
      completedTaskRes.data.report.forEach((item: any) => {
        obj[item.completed_at] = {
          Completed: {
            'Call Back': item.Call_Back,
            Meeting: item.Meeting,
            'Site Visit': item.Site_Visit,
          },
        };
        completedCount = completedCount + item.Meeting + item.Site_Visit;
      });

      setCompletedTaskCount(completedCount);
      setTaskAnalytics(obj);
      setCompletedData({
        data: Object.values(completedTaskRes.data.ChartCount),
        label: Object.keys(completedTaskRes.data.ChartCount),
      });
    }

//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const overdueTaskApiData = {
      uid: user.uid,
      parameter: 'type',
      status: 'Overdue',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const overdueTaskRes = await axiosHandler.post(
      url + '/tasks/tasksReport/associate',
      overdueTaskApiData
    );

    if (overdueTaskRes.data) {
      let obj: {
        [key: string]: {
          'Call Back': number;
          'Site Visit': number;
          Meeting: number;
        };
      } = {};
      overdueTaskRes.data.report.forEach((item: any) => {
        obj[item.due_date] = {
          'Call Back': item.Call_Back,
          Meeting: item.Meeting,
          'Site Visit': item.Site_Visit,
        };
      });

      setOverdueAnalytics(obj);
      setOverdueData({
        data: Object.values(overdueTaskRes.data.ChartCount),
        label: Object.keys(overdueTaskRes.data.ChartCount),
      });
    }

//     axios.defaults.headers.common['x-access-token'] =
// authToken
    const pendingTaskApiData = {
      uid: user.uid,
      parameter: 'type',
      status: 'Pending',
      start_date: startDate,
      end_date: endDate,
      updateData:updateData
    };
    const pendingTaskRes = await axiosHandler.post(
      url + '/tasks/tasksReport/associate',
      pendingTaskApiData
    );

    let scheduledCount = 0;
    if (pendingTaskRes.data) {
      let obj: {
        [key: string]: {
          'Call Back': number;
          'Site Visit': number;
          Meeting: number;
        };
      } = {};
      pendingTaskRes.data.report.forEach((item: any) => {
        obj[item.due_date] = {
          'Call Back': item.Call_Back,
          Meeting: item.Meeting,
          'Site Visit': item.Site_Visit,
        };
        scheduledCount = scheduledCount + item.Meeting + item.Site_Visit;
      });

      setScheduledTaskCount(scheduledCount);
      setPendingAnalytics(obj);
      setScheduledData({
        data: Object.values(pendingTaskRes.data.ChartCount),
        label: Object.keys(pendingTaskRes.data.ChartCount),
      });
    }
    setLoad(false);
  };

  const getLattestData = () => {
    setUpdateData(true)
    setFilterType("All")
  }

  useEffect(() => {
    if(authToken){
      callApi();
    }
  }, [filterType,authToken]);

  useEffect(() => {
    setCallLogData({
      data: Object.values(callLogAnalytics),
      label: Object.keys(callLogAnalytics),
    });
  }, [callLogAnalytics]);

  useEffect(() => {
    setInterestedData({
      data: Object.values(interestedTrendAnalytics),
      label: Object.keys(interestedTrendAnalytics),
    });
  }, [interestedTrendAnalytics]);

  const state = {
    labels: callLogData.label,
    datasets: [
      {
        label: 'Calling Trend',
        backgroundColor: '#4FCE5D',
        borderColor: '#4FCE5D',
        borderWidth: 2,
        data: callLogData.data,
      },
    ],
  };

  const interestedState = {
    labels: interestedData.label,
    datasets: [
      {
        label: 'Interested Lead Trend',
        backgroundColor: '#808000',
        borderColor: '#808000',
        borderWidth: 2,
        data: interestedData.data,
      },
    ],
  };

  const budgetState = {
    labels: budgetData.label,
    datasets: [
      {
        label: 'Interested Budgets',
        backgroundColor: ['#606060', '#FFEDBB'],
        borderColor: ['#606060', '#FFEDBB'],

        barThickness: 40,
        borderWidth: 2,

        data: budgetData.data,
      },
    ],
  };

  const projectState = {
    labels: projectData.label,
    datasets: [
      {
        label: 'Interested Projects',
        backgroundColor: ['#ff9933', '#00468b'],
        borderColor: ['#ff9933', '#00468b'],
        barThickness: 40,
        borderWidth: 2,
        data: projectData.data,
      },
    ],
  };

  const locationState = {
    labels: locationData.label,
    datasets: [
      {
        label: 'Interested Locations',
        backgroundColor: ['#00468b', '#279EA0'],
        borderColor: ['#00468b', '#279EA0'],
        barThickness: 40,
        borderWidth: 2,
        data: locationData.data,
      },
    ],
  };

  const overdueState = {
    labels: overdueData.label,
    datasets: [
      {
        label: 'Overdue Tasks',
        backgroundColor: '#D51E1E',
        borderColor: '#D51E1E',
        borderWidth: 2,
        data: overdueData.data,
      },
    ],
  };

  const completedState = {
    labels: completedData.label,
    datasets: [
      {
        label: 'Completed Tasks',
        backgroundColor: '#279EA0',
        borderColor: '#279EA0',
        borderWidth: 2,
        data: completedData.data,
      },
    ],
  };

  const scheduledState = {
    labels: scheduledData.label,
    datasets: [
      {
        label: 'Scheduled Tasks',
        backgroundColor: '#00008b',
        borderColor: '#00008b',
        borderWidth: 2,
        data: scheduledData.data,
      },
    ],
  };

  const feedBackState = {
    labels: Object.keys(stage),

    datasets: [
      {
        label: 'FeedBack',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 2,
        data: Object.values(stage),
      },
    ],
  };
  let totalCount = 0;
  Object.values(stage).forEach((val) => {
    if (!isNaN(val)) {
      totalCount += val;
    }
  });
  
  const containerStyles = {backgroundColor:theme ? themeColors.backgroundColor:themeColors.backgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  const tableContainerStyles = {backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  const tabContainerStyles = {backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark}
  const buttonStyles = {backgroundColor:theme ? themeColors.cardBackgroundColor:themeColors.cardBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark}
  useEffect(()=>{
    if(isGACalled === false){
      if(user?.uid && user?.profile){
        setIsGACalled(true)
        googleAnalytics('Sales_Analytics_Screen',user)
      }
    }
  },[user])
  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.container} style={containerStyles}>
        <div 
        // className={styles.panel} style={tabContainerStyles}
        className="card p-1 shadow rounded d-flex flex-row justify-content-evenly"
        style={{...tabContainerStyles }}
        >
          <button
            className={styles.contactsButton}
            onClick={() => setPanel('Contacts')}
            style={buttonStyles}
          >
            Contacts Analytics
          </button>
          <button
            className={styles.tasksButton}
            onClick={() => setPanel('Tasks')}
            style={buttonStyles}
          >
            Tasks Analytics
          </button>
          <button
            className={styles.callsButton}
            onClick={() => setPanel('Call Logs')}
            style={buttonStyles}
          >
            Call Logs Analytics
          </button>
        </div>
        <div className={styles.topContainer}>
          <CountBox
            count={totalCount}
            text="Total of Leads"
            style={{ color: '#279f9f'}}
            filter={filterType}
            history={history}
            filterText="Total of Leads"
            source={true}
          />
          <CountBox
            count={stage.FRESH}
            text="Fresh"
            style={{ color: '#ff9933' }}
            filter={filterType}
            history={history}
            filterText="FRESH"
            source={true}
          />
          {
           (showMigratedBucket && stage["MIGRATED LEADS"] > 0) && (
              <CountBox
              count={stage["MIGRATED LEADS"]}
              text="Migrated Leads"
              style={{ color: '#ff9933' }}
              filter={filterType}
              history={history}
              filterText="MIGRATED LEADS"
              source={true}
            />
            )
          }
          <CountBox
            count={stage.CALLBACK}
            text="Call Back"
            style={{ color: '#2FA2D3' }}
            filter={filterType}
            history={history}
            filterText="CALLBACK"
            source={true}
          />
          <CountBox
            count={stage.INTERESTED}
            text="Interested"
            style={{ color: '#279f9f' }}
            filter={filterType}
            history={history}
            filterText="INTERESTED"
            source={true}
          />
          <CountBox
            count={stage.WON}
            text="Closed Won"
            style={{ color: '#D51E1E' }}
            filter={filterType}
            history={history}
            filterText="WON"
            source={true}
          />
          <CountBox
            count={stage.LOST}
            text="Closed Lost"
            style={{ color: '#4FCE5D' }}
            filter={filterType}
            history={history}
            filterText="LOST"
            source={true}
          />
          <CountBox
            count={stage['NOT INTERESTED']}
            text="Not Interested"
            style={{ color: '#279EA0' }}
            filter={filterType}
            history={history}
            filterText="NOT INTERESTED"
            source={true}
          />
          <CountBox
            count={completedTaskCount}
            style={{ color: '#B00020' }}
            filter={filterType}
            text="Completed Visits"
            history={history}
            filterText="Completed"
            source={true}
          />
          <CountBox
            count={scheduledTaskCount}
            text="Scheduled Visits"
            style={{ color: '#279f9f' }}
            filter={filterType}
            history={history}
            filterText="Pending"
            source={true}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.filterBox}
              onClick={() => setShowFilter(!showFilter)}
            >
              <FaFilter
                color={'#279f9f'}
                size="10"
                style={{ marginRight: '10px' }}
              />
              Filter
            </button>
            <button
              className={styles.filterBox}
              onClick={() => getLattestData()}
            >
              <FaFilter
                color={'#279f9f'}
                size="10"
                style={{ marginRight: '10px' }}
              />
              Refersh
            </button>
            {showFilter === true && (
              <div className={styles.filter}>
                <AnalyticsFilter
                  setFilterState={(data) => setFilterType(data)}
                  filterUsed={filterType}
                  showFilter={(data) => setShowFilter(data)}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.graphs}>
          {panel === 'Call Logs' && (
            <>
              <div id="trends">
                <AnalyticsGraph
                  GraphType={Line}
                  data={state}
                  Heading={'Call Log Trends'}
                  title={'Customer Name'}
                  value={'Follow Up Type'}
                  stage={followUp}
                  filter={filterType}
                  type="other"
                  history={history}
                />
              </div>
              <div id="trends" className={commonStyles.graphContainer} style={{display: 'flex',flexDirection: 'column',marginTop:"-2rem",marginBottom:"4rem",...tableContainerStyles}}>
                <IntBarTable
                  type={'callLogs'}
                  data={apiCallLogAnalyticsData}
                  heading={'Call Logs Summary'}
                  usersList={[]}
                  checked={false}
                  teamsData={[]}
                  style={{ width: '100%' }}
                  show={false}
                  filter={filterType}
                  history={history}
                  source={true}
                  taskFilter={{}}
                  leadFilter={{}}
                  callFilter={{}}
                />
              </div>
              <AnalyticsGraph
                GraphType={Line}
                data={interestedState}
                Heading={'Interested Lead Trends'}
                title={'Customer Name'}
                value={'Follow Up Type'}
                stage={followUp}
                filter={filterType}
                type="other"
                history={history}
              />
            </>
          )}

          {panel === 'Tasks' && (
            <>
              <div id="completedTasks">
                <AnalyticsGraph
                  GraphType={Line}
                  data={completedState}
                  Heading={'Completed Tasks'}
                  title={'Customer Name'}
                  value={'Follow Up Type'}
                  stage={taskAnalytics}
                  filter={filterType}
                  type="Completed"
                  history={history}
                />
              </div>
              <div id="overdueTasks">
                <AnalyticsGraph
                  GraphType={Line}
                  data={overdueState}
                  Heading={'Over Due Tasks'}
                  title={'Customer Name'}
                  value={'Follow Up Type'}
                  stage={overdueAnalytics}
                  filter={filterType}
                  type="Overdue"
                  history={history}
                />
              </div>
              <div id="scheduledTasks">
                <AnalyticsGraph
                  GraphType={Line}
                  data={scheduledState}
                  Heading={'Pending Tasks'}
                  title={'Customer Name'}
                  value={'Follow Up Type'}
                  stage={pendingAnalytics}
                  filter={filterType}
                  type="Pending"
                  history={history}
                />
              </div>
            </>
          )}
          {panel === 'Contacts' && (
            <>
              <div id="feedback">
                <AnalyticsGraph
                  GraphType={Pie}
                  data={feedBackState}
                  Heading={'FeedBack'}
                  stage={stage}
                  title={'Feedback Type'}
                  value={'Count'}
                  filter={filterType}
                  type="stage"
                  history={history}
                />
              </div>

              <div id="interestedBudget">
                <AnalyticsGraph
                  GraphType={Bar}
                  data={budgetState}
                  Heading={'Interested Budget Summary'}
                  stage={apiBudgetTableData}
                  title={'Budget Category'}
                  value={'Count'}
                  filter={filterType}
                  type="budget"
                  history={history}
                />
              </div>

              <div id="interestedProject">
                <AnalyticsGraph
                  GraphType={Bar}
                  data={projectState}
                  Heading={'Interested Project Summary'}
                  stage={apiProjectTableData}
                  title={'Project Category'}
                  value={'Count'}
                  filter={filterType}
                  type="project"
                  history={history}
                />
              </div>

              <div id="interestedLocation">
                <AnalyticsGraph
                  GraphType={Bar}
                  data={locationState}
                  Heading={'Interested Location Summary'}
                  stage={apiLocationTableData}
                  title={'Location Category'}
                  value={'Count'}
                  filter={filterType}
                  type="location"
                  history={history}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
    showMigratedBucket: state.organization.showMigratedBucket,
  };
};

export default connect(mapStateToProps)(Analytics);
