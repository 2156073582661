import React,{useEffect,useState} from "react";
import styles from "./SubscriptionDetails.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { numberWithCommas } from "../../Services/booking";
import axios from "axios";
import { url } from "../../Values/constants";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import {getDateFromISOString, getDateIsoString, getDateString2, getFormattedDate} from "../../Values/utils";
import { firestore } from "../../Firebase";
import axiosHandler from "../../Services/axiosHandler";
import { googleAnalytics } from "../../Services/users";

const SubscriptionDetails = ({ theme,validTill,organizationData,userPreference,authToken,user,organization }: any) => {
  const [data, setData] = useState<any[]>([]);
  // const [servicesData, setServicesData] = useState<any[]>([]);
  const [whatsappPackages, setWhatsappPackages] = useState<any[]>([]);
  const [totalWhatsappAmount, setTotalWhatsappAmount] = useState(0);
  const [clientHadWhatsappPackage, setClientHadWhatsappPackage] = useState(false);
  const [load, setLoad] = useState(false);
  const [total,setTotal]=useState("");
  const [isGACalled, setIsGACalled] = useState<any>(false);
  useEffect(()=>{
    if(isGACalled === false){
      if(user?.uid && user?.profile){
        setIsGACalled(true)
        googleAnalytics('Subscription_Screen',user)
      }
    }
  },[user])
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        // axios.defaults.headers.common['x-access-token'] = authToken;
        const res = await axiosHandler.get(url + '/packageDetails/fetchAll', { params: { organization_id: user.organization_id} });
        let licensePackages=res?.data?.data?.licensePackages ? res.data.data.licensePackages : [];
        let whatsappPackages=res?.data?.data?.whatsappPackages ? res.data.data.whatsappPackages : [];
        let totalWhatsappAmount = res?.data?.data?.totalWhatsappPackageAmount ? res.data.data.totalWhatsappPackageAmount : 0;
        let clientHadWhatsappPackage=res?.data?.data?.clientHadWhatsappPackage ? res.data.data.clientHadWhatsappPackage : false;
        licensePackages=licensePackages.filter((item: any) => new Date(item.valid_till) >= new Date());
        setData(licensePackages);
        setWhatsappPackages(whatsappPackages);
        setTotalWhatsappAmount(totalWhatsappAmount);
        setClientHadWhatsappPackage(clientHadWhatsappPackage);
        let totalLicenses = 0;
        licensePackages.forEach((val: any) => {
          const issuedLicenses = parseInt(val.issued_licences);
          if (!isNaN(issuedLicenses)) {
            totalLicenses += issuedLicenses;
          }
        });
        setTotal(totalLicenses > 0 ? totalLicenses.toString() : "");
        setLoad(false);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        setLoad(false);
      }
    };
  
    if (authToken) {
      fetchData();
    }
  
  }, [authToken, user, organizationData]);
  


  let totalLicenses = organizationData && organizationData.no_of_employees;
  let costPerLicense = organizationData && Number(organizationData.cost_per_license);
  let totalAmount = totalLicenses * costPerLicense;
  const history = useHistory();
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const tdStyles = {
    backgroundColor: theme
      ? themeColors.tdBackgroundColor
      : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
    // textAlign: "center"
  };
  const trBorderStyles = {
    borderBottomColor: theme
      ? `1px solid ${themeColors.trBorderColor}`
      : `1px solid ${themeColors.trBorderColorDark}`,
  };
  const thStyles = {
    backgroundColor: theme
      ? themeColors.thBackgroundColor
      : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  return (
    <div className={styles.parent} style={containerStyles}>
       {load === true && <Loading />}
      <div className={styles.container}>
        <div
          className="card p-4 shadow-sm"
          style={{
            borderBottomLeftRadius: "50px",
            borderBottomRightRadius: "50px",
            ...cardContainerStyles,
          }}
        >
           {userPreference.show_subscription_panel === false && 
        <>
        <h6>
            {" "}
            <i className="fas fa-info-circle"></i> {" "}{" "}
            You dont have access to view this panel . Please contact your admin.
          </h6>
        </>
        }
           {userPreference.show_subscription_panel === true && 
        <>
        {/* <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Your account has been
            associated with the partner, ITS FOR YOU <a href="https://www.itsforyou.live/" target="_blank">Know More</a>
          </h6> */}
          <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Your Renewal Date is on <b>{getFormattedDate(organizationData.valid_till)}</b> , To Enjoy Uninterrupted Services, Please renew your
            subscription.
          </h6>
          <h6>
            {" "}
            <i className="fas fa-info-circle"></i> Please go through this link to check our privacy policy <a href="https://read-pro.in/privacy-policy/index.html" target="_blank">Know More</a>
          </h6>
          {/* <button
            className={styles.button}
            onClick={() => {
              history.push("/paymentDetails");
            }}
            style={{ margin: "auto" }}
          >
            Renew Now
          </button> */}
        </>
        }
        </div>
        {/* <h6 className="mx-auto mt-4">Subscription ID: RPCW277000118297318</h6> */}
        {userPreference.show_subscription_panel === true && 
        <>
        {/* <div style={{display:"flex",alignItems:"center"}}> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="mt-4" style={{ width: "60%" }}>
            <h5 className="fw-bold">Subscription Details ( Oid : {organizationData.oid?organizationData.oid:""})</h5>
            <div className="w-100">
              <table
                className="table shadow rounded bg-white"
                style={{ backgroundColor: "red" }}
              >
                <thead className="bg-light">
                  <tr>
                  <th style={{...thStyles,textAlign:"center"}}>S.No</th>
                  <th style={{...thStyles,textAlign:"center"}}>No of Licences</th>
                  <th style={{...thStyles,textAlign:"center"}}>Expiration Date</th>
                  <th style={{...thStyles,textAlign:"center"}}>Package Email Id</th>
                  {/* <th style={{...thStyles,textAlign:"center"}}>Oid</th> */}
                  {/* <th style={thStyles}>Cost Per License  (In INR)</th>
                  <th style={thStyles}>Amount (In INR)</th> */}
                  </tr>
                </thead>
                <tbody>
                      {data.length > 0  ? (
                        data
                        .map((item: any, index: number) => (
                          <>
                          {console.log("item",item)}
                          <tr className="border-bottom" key={index}>
                            <td style={{ ...tdStyles, textAlign: "center" }}>
                              <p className="fw-normal mb-1">{index+1}</p>
                            </td>
                            <td style={{ ...tdStyles, textAlign: "center" }}>
                              <p className="fw-normal mb-1">{item.issued_licences}</p>
                            </td>
                            <td style={{ ...tdStyles, textAlign: "center" }}>
                              <p className="fw-normal mb-1">{getFormattedDate(item.valid_till)}</p>
                            </td>
                            <td style={{ ...tdStyles, textAlign: "center" }}>
                              <p className="fw-normal mb-1">{item.package_email_id}</p>
                            </td>
                            {/* <td style={{ ...tdStyles, textAlign: "center" }}>
                              <p className="fw-normal mb-1">{item.oid}</p>
                            </td> */}
                          </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} style={{ ...tdStyles, textAlign: "center" }}>
                            No subscription details available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                <tfoot>
        <tr>
          <td style={{...tdStyles,textAlign:"center"}}>
            <p className="fw-bold mb-1">{data.length>0?"Total":""}</p>
          </td>
          <td style={{...tdStyles,textAlign:"center"}}>
            <p className="fw-bold mb-1">{total}</p>
          </td>
          <td style={{...tdStyles,textAlign:"center"}}>
            <p className="fw-bold mb-1">{}</p>
          </td>
          <td style={{...tdStyles,textAlign:"center"}}>
            <p className="fw-bold mb-1">{}</p>
          </td>
          {/* <td style={{...tdStyles,textAlign:"center"}}>
            <p className="fw-bold mb-1">{}</p>
          </td> */}
        </tr>
      </tfoot>
              </table>
            </div>
          </div>
          {/* <div className="mt-4" style={{ width: "35%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h6 className="fw-bold">Next Payment</h6>
              <h6
                className={styles.historyLink}
                onClick={() => {
                  history.push("/transactionHistory");
                }}
              >
                Payment History
              </h6>
            </div>
            <div
              className="card p-4 shadow d-flex flex-row justify-content-between"
              style={cardContainerStyles}
            >
              <div>
                <h6 className="fw-bold">{validTill}</h6>
                <h6>(Monthly)</h6>
              </div>
              <div>
                <h4> ₹ {numberWithCommas(totalAmount)}</h4>
                <p className="text-end">*Excluding Tax</p>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end mt-3">
              <button
                className={styles.button}
                onClick={() => {
                  history.push("/upgradeUsers");
                }}
              >
                Upgrade User/ Add-Ons
              </button>
            </div>
          </div> */}
        </div>
        {/* </div> */}
          {
            clientHadWhatsappPackage && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  {/* <span className={styles.YourMarketingPlans}>
              Your Marketing Plans
            </span> */}
                  <h5 className="fw-bold">Your Marketing Plans</h5>
                </div>
                <div className={styles.whatsappPackageDiv}>
                  {/* <div className={styles.Vector}>dddrvgrfew</div> */}
                  <div className={styles.CardSmall1}>
                    <p className={styles.Whatsapp}>Whatsapp</p>
                    <p className={styles.TotalBalance}>
                      Total Balance
                      {/* <span className={styles.textstyle1}>₹</span> */}
                    </p>
                    <p className={styles.textstyle2}>₹ {totalWhatsappAmount}</p>
                  </div>
                  {whatsappPackages.length > 0 ?
                    whatsappPackages.map(service => {
                      return (
                        <div className={styles.Frame1410120917}>
                          <p className={styles.labelName}>{service.package_name}</p>
                          {/* <p className={styles.Value}>12/03/2024</p> */}
                          <span className={styles.Value}>
                            <p className={styles.textstyle2}>{getFormattedDate(service.valid_till)}</p>
                          </span>
                          <span className={styles.Value}>
                            <p className={styles.textstyle2}>₹ {service.no_of_unit}</p>
                          </span>
                        </div>
                      )
                    }) : <p>No Package Available</p>
                  }
                </div>
              </div>
            )
          }
        </>
        }
      </div>
      {/* {userPreference.show_subscription_panel === true && 
        <>
      <div
        className="card px-4 pt-2 w-100 d-flex flex-row justify-content-end position-absolute bottom-0 end-0"
        style={cardContainerStyles}
      >
       <div>
          <h6>accounts@itsforyou.live</h6>
          <p>(For payments and billing questions)</p>
        </div>
         <div>
          <h6>8755964699</h6>
          <p>(Toll Free 24 hours - Monday to Friday)</p>
        </div>
      </div>
        </>
        } */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    validTill: state.organization.organizationData.valid_till,
    organizationData: state.organization.organizationData,
    userPreference:state.userPreference.data,
    authToken:state.user.authToken,
    user: state.user.data,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(SubscriptionDetails);
