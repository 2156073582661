import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./verificationOtp.module.css";
import Logo from "../../Assets/Images/LogoNewFigma.png";
import SignInImage from "../../Assets/Images/singInPageImage.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import OtpInput from 'react-otp-input';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { handleInputChange } from "react-select/src/utils";
import { Route } from "react-router-dom";
import {signInOtpMongo,resendOtp} from "../../Services/auth"
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import {showSnackbarAction} from "../../Redux/actions"
import { googleAnalytics } from "../../Services/users";
// import MMBModel from "../../Components/Modals/MMBModel";


type Props = {
    history: any;
    location:any;
};

const VerifyOtp: FunctionComponent<Props> = ({ history ,location}) => {
    const mobileNumber = location.state?.mobileNumber || "";
    const userId = location.state?.userId || "";
    const validity=location.state?.validity || "";
    const isd=location.state?.isd || "50";
    const userIp=location.state?.userIp || "";
    // console.log("params value",mobileNumber,userId)
    const [otp, setOtp] = useState("");
    const [userProfileUid, setUserProfileUid] = useState<any>();
    const cookies = new Cookies();
    cookies.set("uId", userProfileUid);
    const [load, setLoad] = useState(false);
    const dispatcher = useDispatch();
    const storedTime = localStorage.getItem('timeLeft');
    const initialTime = storedTime ? parseInt(storedTime, 10) : validity; // Convert stored string to number
    const [timeLeft, setTimeLeft] = useState<number>(initialTime);
    const [openModal,setOpenModal]=useState(false)
    const [mmbUrl,setMmbUrl]=useState("");
    const [isResendActive, setIsResendActive] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');

    useEffect(()=>{
      googleAnalytics("Verify_Otp_Screen")
  },[])

    useEffect(() => {
        if (timeLeft > 0) {
          const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => {
              const newTime = prevTime - 1;
              localStorage.setItem('timeLeft', newTime.toString()); // Convert number to string for storage
              return newTime;
            });
          }, 1000);
    
          return () => clearInterval(intervalId); // Cleanup interval on unmount
        } else {
          localStorage.removeItem('timeLeft'); // Remove time from localStorage when timer reaches 0
          setIsResendActive(true); 
        }
      }, [timeLeft]);

      const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      };
    

    const changeLoad = (value: boolean) => {
        setLoad(value);
      };

    const login = async () => {
      if (otp.length < 4) {
        setShowErrorMessage('OTP must be of 4 digits')
        // dispatcher(showSnackbarAction('OTP must be of 4 digits', 'error'));
        return;
    }
        // signInFirebase(
        //   email,
        //   password,
        //   history,
        //   dispatcher,
        //   changeLoad,
        //   setUserProfileUid
        // );
        const res= await signInOtpMongo(
         mobileNumber,
         otp,
         userId,
         isd,
          history,
          dispatcher,
          changeLoad,
          setUserProfileUid,
          (val:any)=>setOpenModal(val) ,
          (val:any)=>setMmbUrl(val),
          (value=>{setShowErrorMessage(value)})
        );

        // console.log("res.data.message",res.data.message)

        // if(res.data.message==="Wrong OTP provided"){
        //     dispatcher(
        //         showSnackbarAction(
        //           'wrong otp',
        //           'error'
        //             )    )
        // }
      };

    const renderInput = (props: any, index: number) => {
        // Determine border color based on input value
        const isFilled = otp[index] !== undefined && otp[index] !== '';
        const borderColor = isFilled ? '#303030' : '#ccc'; // Change border color as needed

        return (
            <input
                {...props}
                style={{
                    ...props.style,
                    borderColor: borderColor,
                }}
                onKeyDown={(e) => {
                  if (
                    !/^[0-9]$/.test(e.key) && // Allow only numbers
                    e.key !== "Backspace" && // Allow backspace
                    e.key !== "ArrowLeft" && // Allow left arrow for navigation
                    e.key !== "ArrowRight" && // Allow right arrow for navigation
                    e.key !== "Tab"&& // Allow tab for navigation
                    !(e.ctrlKey && (e.key === 'v' || e.key === 'V'))
                  ) {
                    e.preventDefault();
                  }
                }}
            />
        );
    };

    const handleRequestOtp = () => {
        // history.replace("/"); 
        history.replace("/signInOtp"); 
};

// console.log("iip and isd",userIp,isd)

const handleResendOtp = async () => {
  if (!isResendActive) return;
  setIsResendActive(false);
    await resendOtp(mobileNumber,isd,userIp, history,dispatcher,(value=>{}));
    setTimeLeft(validity); // Reset the timer to 5 minutes
    
};


    return (
        <div className={styles.container}>
            <div className={styles.box1}>
                <img src={Logo} alt="Logo" className={styles.logo} />
                <img src={SignInImage} alt="Image" className={styles.imageFromBottom} />
            </div>
            {/* <div className={styles.box2}>
                <div className={styles.backIcon}>
                    <IoIosArrowRoundBack color="#606060" onClick={handleRequestOtp}/>
                    <span className={styles.backText}>Back to login</span>
                </div>
                <div className={styles.content}>
                    <h1 className={styles.heading}>Verify</h1>
                    <pre className={styles.description1}>
You’ll receive a 4-digit verification code on
                    </pre>
                    <pre className={styles.description2}>
                    <span className={styles.mobileNumber}>{mobileNumber}</span>, if you are registered with us
                    </pre>
                </div>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={renderInput}
                    inputStyle={styles.otpInput}
                    containerStyle={styles.otpContainer}
                    shouldAutoFocus={true}
                />
                <h6 className={styles.errorStyle}>{showErrorMessage}</h6>
                <div className={styles.actionSection}>
                  <button className={styles.button} onClick={login}>Verify</button>
                  <p className={styles.otpTimer}>
                    You will receive OTP within <span className={styles.timer}>{formatTime(timeLeft)}</span> min
                  </p>
                  <div className={styles.resendSection}>
                    <p className={styles.resendText}>Did not get Verification Code?</p>
                    <div
                      className={`${styles.resendButton} ${isResendActive ? '' : styles.disabledResendButton}`}
                      onClick={handleResendOtp}
                    >
                      <RefreshRoundedIcon className={styles.resendIcon} style={{ fontSize: "large" }} />
                      <p className={styles.resendText} style={{ textDecoration: "underline" }}>Resend Code</p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className={styles.box2}>
  <div className={styles.backIcon}>
    <IoIosArrowRoundBack color="#606060" onClick={handleRequestOtp} />
    <span className={styles.backText}>Back to login</span>
  </div>
  
  <div className={styles.content}>
    <h1 className={styles.heading}>Verify</h1>
    <pre className={styles.description1}>
      You’ll receive a 4-digit verification code on
      <pre className={styles.description2}>
      <span className={styles.mobileNumber}>{mobileNumber}</span>, if you are registered with us
    </pre>
    </pre>
    

    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderInput={renderInput}
      inputStyle={styles.otpInput}
      containerStyle={styles.otpContainer}
      shouldAutoFocus={true}
    />
    <h6 className={styles.errorStyle}>{showErrorMessage}</h6>

    {/* actionSection now inside box2 */}
    <div className={styles.actionSection}>
      <button className={styles.button} onClick={login}>Verify</button>
      <p className={styles.otpTimer}>
        You will receive OTP within <span className={styles.timer}>{formatTime(timeLeft)}</span> min
      </p>
      <div className={styles.resendSection}>
        <p className={styles.resendText}>Did not get Verification Code?</p>
        <div className={`${styles.resendButton} ${isResendActive ? '' : styles.disabledResendButton}`} onClick={handleResendOtp}>
          <RefreshRoundedIcon className={styles.resendIcon} style={{ fontSize: "large" }} />
          <p className={styles.resendText} style={{ textDecoration: "underline" }}>Resend Code</p>
        </div>
                <p className={styles.resendText}>If you have not received OTP, Please try <span className={styles.loginViaEmailSpan} onClick={() => {
                  // history.replace("/signInEmail");
                  history.replace("/");
                }}>Login Via Email</span></p>
      </div>
    </div>
  </div>
</div>

            {/* {openModal === true && 
            <MMBModel
            openModal={openModal}
              url={mmbUrl}
            />
            } */}
        </div>
    );
};

export default VerifyOtp;
