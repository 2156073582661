import React, { useEffect,useState } from "react";
import { BrowserRouter as Router, Route,useHistory  } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import AuthRoute from "./AuthRoute";
import { checkUser, checkUserMongo } from "../Services/auth";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";
import { connect, useDispatch } from "react-redux";
import { updateTheme, setconstants, setRole, updateUserStatus, setAuthToken,setUser} from "../Redux/actions";
import { url, auto_key, iv_key, decryptPAN ,decryptautoLogin} from "../Values/constants";
import { setConstants } from "../Services/resources";
import crypto from "crypto";
import { types } from "util";
import axiosHandler from "../Services/axiosHandler"
import {fetchOrganizationDataMongo,fetchUserDataMongo} from "../Services/auth"
import { platform } from "os";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "universal-cookie"

const AppRoutes = ({
  userStatus,
  userRole,
  theme,
  authToken,
  user,
  constants,
}: {
  userStatus: boolean;
  userRole: string;
  theme: any;
  authToken: any;
  user: any;
  constants: any;
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();

  
  // Function to get device info
  const getDeviceInfo = async () => {
    try {
      // Check localStorage for existing device info
      let device_id = localStorage.getItem('device_id');
      let device_ip: any = localStorage.getItem('device_ip');
      let device_name = localStorage.getItem('device_name');

      // Generate a new device_id if not present
      if (!device_id) {
        device_id = uuidv4();
        localStorage.setItem('device_id', device_id);
      }

      // Get device name from User-Agent
      if (!device_name) {
        device_name = window.navigator.userAgent;
        localStorage.setItem('device_name', device_name);
      }

      // Fetch IP only if not already stored
      if (!device_ip) {
        try {
          const response = await fetch('https://ipwhois.app/json/');
          if (response.ok) {
            const data = await response.json();
            device_ip = data.ip || "Unknown IP";
            localStorage.setItem('device_ip', device_ip);
          } else {
            device_ip = "Unknown IP";
          }
        } catch (error) {
          console.error("Failed to fetch IP address:", error);
          device_ip = "Unknown IP";
        }
      }

      // Return collected device info
      return { device_id, device_name, device_ip };
    } catch (error) {
      console.error("Error in getDeviceInfo:", error);
      return {
        device_id: "Unknown Device ID",
        device_name: "Unknown Device Name",
        device_ip: "Unknown IP",
      };
      // throw new Error("Failed to collect device info");
    }
  };

  // Usage
  getDeviceInfo()
 const [conatactID,setConatactID]=useState("")
 const [userId,setUserId]=useState("")
 const cookies = new Cookies();


  // useEffect(async() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const encryptedToken = decodeURIComponent(urlParams.get('token') || "");
  //   if (encryptedToken) {
        
  //     const obj = JSON.parse(decryptPAN(encryptedToken));

  //     console.log("Rishabh Gupta Login url", obj);

  //     const {device_type,userId,timeStamp}=obj;
  //     console.log("Current Time:", Date.now());
  //     console.log("Token Expiration Time:", timeStamp);

  //     if (timeStamp < Date.now()) {
  //       alert('Token has expired');
  //       // window.history.replaceState(null, '', window.location.pathname)
  //       return;
  //   }

  //   const callData=async(userId:any,device_type:any)=>{
  //     try {

  //       const params={userId:userId,device_type:device_type}
  //   const response = await axiosHandler.post('/auth/getUserOid',  params);
  //       return response.data;
  //     } catch (error) {
  //       return false;
  //     }
  //   }

  //   const data=await callData(userId,device_type);
  //     if(!data){
  //       alert('User not found');
  //       // window.history.replaceState(null, '', window.location.pathname)
  //       return;
  //     }else{
  //       const user=data?.data?.user;
  //         dispatcher(setRole(userData.role));
  //     dispatcher(updateUserStatus(true));
  //     localStorage.setItem('user', JSON.stringify(userData));
  //     dispatcher(setAuthToken({ authToken: token }));
  //    window.history.replaceState(null, '', window.location.pathname)
  //     }
  //   //   const { token, data } = obj;
  //   //   const userData = JSON.parse(data);

      
  //   //   dispatcher(setRole(userData.role));
  //   //   dispatcher(updateUserStatus(true));
  //   //   localStorage.setItem('user', JSON.stringify(userData));
  //   //   dispatcher(setAuthToken({ authToken: token }));
  //   //  window.history.replaceState(null, '', window.location.pathname)
  //   } else {
  //     // Normal code if no token is present
  //     let userData: any = localStorage.getItem("user");
  //     let parsedUser = JSON.parse(userData);

  //     checkUserMongo(dispatcher, parsedUser);
  //     setTheme();
  //   }
  // }, [authToken]);

  useEffect(() => {
    const fetchData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedToken = decodeURIComponent(urlParams.get('token') || "");
        if (encryptedToken) {
          const userAgent = navigator.userAgent.toLowerCase();
          if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            const url = `https://property.magicbricks.com/microsite/lp/download-readpro/?token=${encodeURIComponent(encryptedToken)}`;
            window.location.href = url;
          } else {
            const obj = JSON.parse(decryptautoLogin(encryptedToken));

            const { device_type, userId, timeStamp,contact_id } = obj;
            // console.log("Current Time:", Date.now());
            console.log("Token Expiration Time:", obj);

            // Check if token has expired

            if (timeStamp < Date.now()) {
              window.history.replaceState(null, '', window.location.pathname);
              dispatcher(updateUserStatus(false));
                alert('Link has expired');
                return;
            }

            const user = JSON.parse(localStorage.getItem("user") || "{}");
            let loginObj: Record<string, any> = {};
            console.log("useerfsdfsd",user)
            // if(contact_id && Object.keys(loginObj).length === 0 ){
            //   console.log("i ranaaa")
            //   alert('Sorry, you are not authorized to view this page');
            //   window.history.replaceState(null, '/', window.location.pathname)
            //     // setTimeout(() => {
            //     //   window.location.reload();
            //     // }, 1000); 
            //     return;
            // }
             if (Object.keys(user).length !== 0) {
              loginObj["login_organization_id"] = user.organization_id;
              loginObj["login_uid"] = user.uid;
              loginObj["login_session_id"]=user.session_id;
            }


            // Function to call API and get user data
            const callData = async (userId:any, device_type:any,contact_id:any) => {
                try {
                  const deviceInfo = await getDeviceInfo();
                    const params = { 
                      userId: userId, 
                      contact_id:contact_id,
                      device_type: device_type,       
                      device_id: deviceInfo.device_id, 
                      device_ip: deviceInfo.device_ip, 
                      device_name: deviceInfo.device_name ,
                      ...loginObj
                    };
                    // const params = { userId: userId, device_type: device_type ,...loginObj};
                    // console.log("params rishabh",params)
                    const response = await axiosHandler.get('/auth/getUserOid', {params:params});
                    console.log("responses.data",response.data)
                    if(!response.data.success){

                      if(response.data.message==="user of same organization"){
                        return "user of same organization"
                      }else if(response.data.message==="Please Login First"){
                        return "Please Login First"
                      }else if (response.data.message==="user of different organization"){
                        return "user of different organization"
                      }else{
                        console.log("rishabh")
                        return false
                      }
                      
                      
                    }
                    return response.data;

                } catch (error) {
                    return false;
                }
            };

            // Fetch the user data using the decrypted userId and device_type
            const data = await callData(userId, device_type,contact_id);

            // console.log("rishabh data",data)
            if (data==="user of same organization") {
              if (contact_id) {
                console.log("Setting contact_id and preparing for navigation");
                setConatactID(contact_id); // Triggers the useEffect to handle navigation
                localStorage.setItem("contact_id",contact_id)
                // localStorage.setItem("userId",userId)
              }
              console.log("here1")
              window.history.replaceState(null, '/', window.location.pathname)
              // history.replace("/contacts")

              setTimeout(() => {
                window.location.reload();
              }, 1000); 
              console.log("here2")
              return;

            }else if(data==="Please Login First"){
              window.history.replaceState(null, '/', window.location.pathname)
              alert("Please Login First");
              setTimeout(() => {
                window.location.reload();
              }, 1000); 
              return;
            }else if (data==="user of different organization"){
              
              if(Object.keys(user).length !== 0){
               console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
               alert('Sorry, you are not authorized to view this page');
               setTimeout(() => {
                 window.location.href = '/contacts'
               }, 1000); 
               // window.history.replaceState(null, '/contacts', window.location.pathname)
               return;
              }else{
               window.history.replaceState(null, '/', window.location.pathname)
               alert('Sorry, you are not authorized to view this page');
               setTimeout(() => {
                 window.location.reload();
               }, 1000); 
               return;
              }
         }else if (!data){
              window.history.replaceState(null, '/', window.location.pathname)
                alert('Sorry, you are not authorized to view this page');
                setTimeout(() => {
                  window.location.reload();
                }, 1000); 
                return;
            }            
            else {
                const user = data?.data?.user;
                // console.log("toesnjewnfuwenfuw",user,user.token,user.role)
              if(user){
                localStorage.setItem('user', JSON.stringify(user));
                dispatcher(setAuthToken({ authToken: user.token }));


                // Update the app's state with user data
                if (user.role === "superAdmin") {
                  dispatcher(
                    setUser({
                      user_first_name: "Super Admin",
                      user_last_name: "",
                      user_email: user.user_email,
                    })
                  );
                }
                if (user.role === "organization") {
                  fetchOrganizationDataMongo(user, dispatcher);
                } else if (user.role !== "superAdmin") {
                  fetchUserDataMongo(user, dispatcher);
                }
                dispatcher(setRole(user.role));
                dispatcher(updateUserStatus(true));

                if (contact_id) {
                  // window.location.href = `/contactDetailsScreen?contact_id=${contact_id}`;
                  // localStorage.setItem('contact_id', contact_id);
                  setConatactID(contact_id)
                  // setUserId(userId)
              }
                
                // Replace URL to remove the token parameter after successful login
                window.history.replaceState(null, '', window.location.pathname);
                return
              }
            }
          }
            // Decrypt the token
            
        } else {
            // If no token is present, proceed with normal flow
            let userData:any = localStorage.getItem("user");
            let parsedUser = JSON.parse(userData);

            checkUserMongo(dispatcher, parsedUser);
            setTheme();
        }
    };

    fetchData(); // Invoke the async function
}, [authToken]); // Add dependencies



  useEffect(() => {
    if (authToken) {
      setConstants(authToken, dispatcher);
    }

  }, [authToken])

  const setTheme = async () => {
    let isLightMode = await localStorage.getItem("isLightMode") ? localStorage.getItem("isLightMode") : "true";
    if (isLightMode === "true") {
      dispatcher(updateTheme(true));
    } else {
      dispatcher(updateTheme(false));
    }
  };


  return (
    <Router>
      {userStatus !== undefined && (
        <>
          {userStatus === false && <Route component={AuthRoute} />}
          {userStatus === true && (
        <Route
          render={(props) => (
            <HomeRoute
              {...props} 
              contact_id={conatactID || ""} 
              // userId={userId || ""}
            />
          )}
        />
      )}
        </>
      )}
      <Route path="/resetPassword" component={PasswordReset} />
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
    theme: state.theme.isLightMode,
    authToken: state.user.authToken,
    user: state.user.data,
    constants: state.constants.data,
  };
};

export default connect(mapStateToProps)(AppRoutes);
