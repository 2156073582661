import React, { useEffect, useState } from "react";
import style from './ResponsePropertyWise.module.css';
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import axiosHandler from "../../Services/axiosHandler";
import { url} from "../../Values/constants";
import {fetchSingleMBData} from "../../Services/contacts"
import moment from "moment";
import {
  showSnackbarAction,
} from '../../Redux/actions'
import Lottie from "react-lottie";
import noData from "../../Assets/animations/no-data.json";

type props = {
  user: any;
};

const ResponsesPropertyWise: React.FC<props> = ({
  user
}) => {
  const [activeTab, setActiveTab] = useState<string>("Properties");
  const history = useHistory();
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [secondTotalCount, setSecondTotalCount] = useState<number>(0);
  const [page,setPage]=useState("0")
  const [otherPage,setOtherPage]=useState("0")
  const [responses,setResponses]=useState([
    {
      id: "",
      pDt: "",
      prc: "",
      pCov: "",
      desc: "",
      propRes: [
        {
          name: "",
          contact: "",
          sentOn: "",
          userType: "",
          message:"",
          mbLeadRfnum: "",
        },
      ],
    },
  ])

  const [otherResponses,setOtherResponses]=useState([
    {
      name: "",
      prc: "",
      addr: "",
      contact: "",
      sentOn: "",
      message:"",
      mbLeadRfnum: "",
    },
  ])
  const dispatcher = useDispatch();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function calculateEpochTime() {
    const today = new Date();
  
    // Calculate the end date (today's date)
    const endDate = today;
  
    // Calculate the start date (90 days ago from today's date)
    let startDate = new Date(today);
    startDate.setDate(today.getDate() - 90);
  
    // Adjust for the middle of the month if today's date is not the first
    if (today.getDate() !== 1) {
      // Set the start date to the same day of the month, 90 days back
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 90);
    } else {
      // If it's the first of the month, subtract 90 days from the first
      startDate.setMonth(today.getMonth() - 3);
      startDate.setDate(1);
    }
  
    // Convert start and end dates to epoch time in milliseconds
    const startEpochMillis = startDate.getTime();
    const endEpochMillis = endDate.getTime();
  
    return {
      startEpochMillis,
      endEpochMillis
    };
  }
  
  // Example Usage:
  const { startEpochMillis, endEpochMillis } = calculateEpochTime();
  
// console.log("page",page)
  const fetchPropResponses = async () => {
    let apiUrl;
    {activeTab === "Properties" ?
      apiUrl = url+"/mbResponses/propresponses"
      :
      apiUrl = url+"/mbResponses/companyresponses"
    }
    let pag
    {activeTab === "Properties" ?
      pag = page
      :
      pag = otherPage
    }
    const payload = {
      page: pag,
      pagesize: "20",
      uids: [user.user_oid],
      userType: "A",
      startDate: startEpochMillis,
      endDate: endEpochMillis,
    };
  
    try {
  
      const response = await axiosHandler.post(apiUrl, payload);
      if (response.data.responses && Array.isArray(response.data.responses)) {
        const newResponses = response.data.responses;
  
        {activeTab === "Properties" ?
          // setResponses((prevResponses) =>
          //   page === "0" ? newResponses : [...prevResponses, ...newResponses]
          // )
          setResponses(newResponses)
          :
          // setOtherResponses((prevResponses) =>
          //   otherPage === "0" ? newResponses : [...prevResponses, ...newResponses]
          // )
          setOtherResponses(newResponses)
        }
  
        // setTotalCount(response.data.totalCount || 0); // Update total count
        // {activeTab === "Properties" ?
        //   setSecondTotalCount(response.data.othCnt )
        //   :
        //   setSecondTotalCount(response.data.propWiseCnt )
        // } 
        // Update total count
        // {activeTab === "Properties" ?
        //   setPage((parseInt(page) + 1).toString())
        // :
        // setOtherPage((parseInt(otherPage) + 1).toString())} // Increment page for next fetch
        setNextPageAvailable(response.data.nextPageAvailable); // Update availability of next page
      } else {
        // setTotalCount(0); // No data
        setNextPageAvailable(false); // No more pages available
      }
    } catch (error:any) {
      // Handle errors
      console.error("Error fetching responses:", error.response || error);
      throw error;
    }
  };

  const fetchPropCount = async () => {
    let apiUrl = url+"/mbResponses/propresponses"
      
    let pag
    {activeTab === "Properties" ?
      pag = page
      :
      pag = otherPage
    }
    const payload = {
      page: pag,
      pagesize: "20",
      uids: [user.user_oid],
      userType: "A",
      startDate: startEpochMillis,
      endDate: endEpochMillis,
    };
  
    try {
  
      const response = await axiosHandler.post(apiUrl, payload);
      if (response.data.responses && Array.isArray(response.data.responses)) {
  
        setTotalCount(response.data.totalCount); // Update total count
          setSecondTotalCount(response.data.othCnt )
      } else {
        setTotalCount(0); // No data
      }
    } catch (error:any) {
      // Handle errors
      console.error("Error fetching responses:", error.response || error);
      throw error;
    }
  };

  useEffect(()=>{
    setPage('0')
    setOtherPage('0')
    setNextPageAvailable(false)
    // fetchPropResponses()
  },[user,activeTab])

  useEffect(()=>{
    fetchPropResponses()
  },[user,activeTab,page,otherPage])

  useEffect(()=>{
    fetchPropCount()
  },[user])

  const fetchMBData = async (leadId: string,uid:any) => {
    try {
  
        // let authToken = await firestore().collection('values').doc('constants').get();
        // let AUTHTOKEN = authToken.data()?.authToken ? authToken.data()?.authToken : "";
  
        let apiData =  {
          contact_id: leadId,
          uid:uid
        }
  // console.log("apiData",apiData)
      const backendUrl = url + "/mbResponses/fetchLeadByMB";
  
      const res = await axiosHandler.get(backendUrl, {params:apiData})
  
  
        return res.data.data
  
  
    } catch (error) {
  
      console.log('Lead fetching error', error);
      return {};
    }
  };


  const fetchData = async (contact_id:any) => {

    // console.log("VFCD","I rannnn1 1111",contact_id)
    if (contact_id) {
      try {
        // Assuming fetchSingleMBData is an async function that fetches data
        const data = await fetchMBData(contact_id,user.uid);
        // console.log("contact_details data",data)
        // Navigate to /contactDetails and pass contact_id in state

        const formatDate = (date:any) => {
          return date ? moment(date).format('DD MMM YY, LT') : '';
        };
        history.replace({
          pathname: "/contactDetails",
          state: { detail:{  ...data,
            created_at: formatDate(data['created_at']),
            lead_assign_time: formatDate(data['lead_assign_time']),
            next_follow_up_date_time: formatDate(data['next_follow_up_date_time']),
            modified_at: formatDate(data['modified_at']),
            stage_change_at: formatDate(data['stage_change_at']),
            feedback_time: formatDate(data['feedback_time']),
            due_date: formatDate(data['due_date']),
          } } // You can also pass the fetched data if needed
        });
      } catch (error) {
        dispatcher(
          showSnackbarAction(
            "Data Not Found!!",
            "warning"
          ))
        console.error("Error fetching contact data", error);
      }
    }
  };
  

  const renderContent = () => {
    if (activeTab === "Properties") {
      return responses.map((property) => (
        <div key={property.id} className={style.propertyCard}>
          <div className={style.propertyHeader}>
            <span>
              Property ID: {property.id} |{" "}
              <a href="#" className={style.propertyLink}>
                {property.desc}
              </a>
            </span>
          </div>
          <p className={style.propertySubHeader}>
            Posted: {property.pDt} | Price: {property.prc} | Coverage: {property.pCov}
          </p>

          {property.propRes.map((response, index) => (
            <div key={`${property.id}-${index}`} className={style.responseCard}>
              <div className={style.tableHeader}>
                <span className={style.tableHeaderCell}>Response Received From</span>
                <span className={style.tableHeaderCell}>Contact Details</span>
                <span className={style.tableHeaderCell}>Received On</span>
              </div>
              <div className={style.tableRow} 
              onClick={()=>fetchData(response.mbLeadRfnum)}
              >
                <span className={style.tableCell}>{response.name}</span>
                <span className={`${style.tableCell} ${style.companyContact}`}>{"Verified"}</span>
                <span className={style.tableCell}>{response.sentOn}</span>
              </div>
              <div className={style.messageSection}>
                <p className={style.readMessageLink}>
                  Message : 
                </p>
                <p className={style.messageContent}>{response.message}</p>
              </div>
            </div>
          ))}
        </div>
      ));
    } else if (activeTab === "Requirements/Buyers") {
      return <><div>
          <Lottie options={defaultOptions} height={300} width={300} />
          <h5 className="text-center">No data Available</h5>
    </div></>
    } else if (activeTab === "Company") {
      return otherResponses.map((company, index) => (
        <div  className={style.companyCard}>
        <div className={style.tableHeader}>
          <span className={style.tableHeaderCell}>Response Received From</span>
          <span className={style.tableHeaderCell}>Contact Details</span>
          <span className={style.tableHeaderCell}>Received On</span>
        </div>
        <div className={style.tableRow} 
        onClick={()=>fetchData(company.mbLeadRfnum)}
        >
          <span className={style.tableCell}>{company.name}</span>
          <span className={`${style.tableCell} ${style.companyContact}`}>{"Verified"}</span>
          <span className={style.tableCell}>{company.sentOn}</span>
        </div>
        <div className={style.messageSection}>
          <p className={style.readMessageLink}>
            Message : 
          </p>
          <p className={style.messageContent}>{company.message}</p>
        </div>
      </div>
      ));
    }
  };

  return (
    <div className={style.responseContainer}>
      <div className={style.responseHeader}>
        <h2>My Responses (Last 3 Months)</h2>
        <div className={style.tabContainer}>
        <button
          className={`${style.tab} ${activeTab === "Properties" ? style.activeTab : ""}`}
          onClick={() => setActiveTab("Properties")}
        >
          Properties ({totalCount})
          {/* ({activeTab === "Properties" ? totalCount : secondTotalCount}) */}
        </button>
        <button
          className={`${style.tab} ${activeTab === "Requirements/Buyers" ? style.activeTab : ""}`}
          onClick={() => setActiveTab("Requirements/Buyers")}
        >
          Requirements/Buyers (0)
        </button>
        <button
          className={`${style.tab} ${activeTab === "Company" ? style.activeTab : ""}`}
          onClick={() => setActiveTab("Company")}
        >
          Company ({secondTotalCount})
          {/* ({activeTab === "Company" ? totalCount : secondTotalCount}) */}
        </button>
        </div>
      </div>
      <div className={style.switchView}>
        <button
          className={style.toggleView}
          onClick={() => history.push("/response")}
        >
          Switch to Month-wise display
        </button>
      </div>

      <div className={style.content}>{renderContent()}</div>
      {/* {(nextPageAvailable&&activeTab !== "Requirements/Buyers")&&
        <div className={style.switchView}>
          <button
            className={style.toggleView}
            onClick={() => fetchPropResponses()}
          >
            Load More ....
          </button>
        </div>
      } */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {(activeTab === "Properties" && page !== "0") || (activeTab === "Company" && otherPage !== "0") ? (
          <div className={style.switchView}>
            <button
              className={style.toggleView}
              onClick={() => {
                
                // Scroll to the top of the screen
                window.scrollTo({ top: 0, behavior: "smooth" });

                if (activeTab === "Properties") {
                                setPage((parseInt(page) - 1).toString())
                                }else{
                                setOtherPage((parseInt(otherPage) - 1).toString())
                                }}
                      }
            >
              {"<"} Previous Page
            </button>
          </div>
        ) : (
          <div style={{ width: "120px" }}></div> 
        )}
        {(nextPageAvailable&&activeTab !== "Requirements/Buyers") && (
          <div className={style.switchView}>
            <button
              className={style.toggleView}
              onClick={() => {
                // Scroll to the top of the screen
                window.scrollTo({ top: 0, behavior: "smooth" });
        
                // Handle the page update logic
                if (activeTab === "Properties") {
                  setPage((parseInt(page) + 1).toString());
                } else {
                  setOtherPage((parseInt(otherPage) + 1).toString());
                }
              }}
            >
              Next Page {">"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(ResponsesPropertyWise);
// export default ResponsesPropertyWise;
